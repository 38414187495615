.back-btn-cont {
    z-index: 4000;
    position: fixed;
    top: 120px;
    left: 28px;
}
.back-btn-cont a {
    text-decoration: none;
}
.back-btn-cont img {
    max-width: 48px;
    cursor: pointer;

    transition: all 200ms ease-in-out;
}

.back-btn-cont img:hover {
    transform: scale(1.1);
}

.back-btn-cont img:active {
    transform: scale(.95);
}

@media (max-width: 768.98px) {
    .back-btn-cont {
        top: 90px;
        left: 16px;
    }
    .back-btn-cont img {
        max-width: 42px;
    }
}

@media (max-width: 375.98px) {
    .back-btn-cont img {
        max-width: 40px;
    }
}
@media (max-width: 320.98px) {
    .back-btn-cont img {
        max-width: 36px;
    }
    .back-btn-cont {
        top: 78px;
        left: 12px;
    }
}