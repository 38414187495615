@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "LT Bulletin";
  src: url('/src/fonts/LTBulletin-Medium.ttf');
}

@font-face {
  font-family: "Manta Style";
  src: url('/src/fonts/Manta\ Style\ Script\ DEMO.ttf');
}
@font-face {
  font-family: 'Bourdos';
  src: url('/src/fonts/Bourdos_PERSONAL_USE_ONLY.otf');
}

body * {
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'LT Bulletin';
  font-weight: 400;
}

body div.content {
  position: relative;
  top: 90px;
  /* margin-top: 90px; */
  background-color: #fff;

  height: calc(100vh - 90px);
  max-height: 100%;
  overflow-y: auto;

  box-sizing: border-box;
}

/* width */
body div.content::-webkit-scrollbar {
  width: 8px;
}

/* Track */
body div.content::-webkit-scrollbar-track {
  /* background: #dfdfdf; */
  background: #fff;
}

/* Handle */
body div.content::-webkit-scrollbar-thumb {
  background: #4B7DA0;
}

@media (max-width: 768.98px) {
  body div.content {
      top: 72px;
      height: calc(100vh - 72px);

      padding-bottom: 90px;
  }
}
@media (max-width: 375.98px) {
  body div.content {
    padding-bottom: 60px;
  }
}
@media (max-width: 320.98px) {
  body div.content {
    top: 60px;
    height: calc(100vh - 60px);
  }
}