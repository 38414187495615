nav.sights-navbar {
    z-index: 9000;
    display: flex;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    max-width: 100%;

    height: 90px;

    background-color: #fff;
    /* box-shadow: 0 4px 12px rgba(50, 50, 50, .15); */

    box-sizing: border-box;
    padding: 0 12px;
}
nav.sights-navbar.landing-page {
    position: fixed;
    left: unset;
    top: 28px;
    right: 42px;
    background-color: transparent;
    width: auto;
    height: auto;
    padding: 0;
}
nav.sights-navbar.landing-page a {
    display: none;
}
nav.sights-navbar > a {
    display: inline-block;
    position: relative;
    height: 100%;
    padding: 6px 0;
    box-sizing: border-box;
}
nav.sights-navbar a img.sights-logo {
    height: 100%;
    margin-right: auto;
    margin-left: 60px;
}

nav.sights-navbar div.nav-links.desktop {
    display: flex;
    margin-left: auto;
    margin-right: 60px;
    height: 100%;
}

nav.sights-navbar.landing-page div.nav-links.desktop {
    display: none;
}

nav.sights-navbar div.nav-links.desktop a {
    display: inline-flex;
    align-items: center;
    padding: 0 32px;
    text-decoration: none;

    font-family: "LT Bulletin";
    font-size: 20px;

    color: black;
    height: 100%;
}

nav.sights-navbar div.nav-links.desktop a.active {
    /* border-bottom: solid 2px black; */
    background-color: rgba(229, 167, 36, .75);
}

nav.sights-navbar .menu-icon {
    display: none;
    max-height: 20px;
    margin-left: auto;
    /* margin-right: 24px; */

    cursor: pointer;

    background-color: #fff;
    /* box-sizing: border-box; */
    padding: 8px;
    border-radius: 6px;
}
nav.sights-navbar.landing-page .menu-icon {
    display: block;
    background-color: #fff;

    padding: 10px;
    box-sizing: content-box;
    border: none;
    border-radius: 4px;

    margin: 0;

    box-shadow: 0 0 8px rgba(255,255,255, .5);
}

nav.sights-navbar div.nav-links.small-screen {
    display: none;
    flex-direction: column;
    
    position: fixed;
    top: 100px;
    right: 0;
    width: 100%;
    max-width: 240px;
    
    background-color: #fff;
    box-sizing: border-box;
    /* padding: 20px 32px; */
    box-shadow: -4px 8px 12px rgba(0, 0, 0, .1);

    border-bottom-left-radius: 4px;

    transform: translateX(100%);
    overflow-y: hidden;

    transition: all 400ms ease-in-out;
}
nav.sights-navbar.landing-page div.nav-links.small-screen {
    display: flex;
    top: 90px;
    right: 42px;

    
    transform: translateX(120%);
}

nav.sights-navbar div.nav-links.small-screen.show {
    transform: translateX(0);
}
nav.sights-navbar div.nav-links.small-screen > div {
    position: relative;
    width: 100%;
    padding: 24px 32px;
    box-sizing: border-box;
}

nav.sights-navbar div.nav-links.small-screen a {
    display: inline-block;
    width: 100%;

    text-align: right;
    text-decoration: none;

    color: black;
    /* font-family: "LT Bulletin"; */
    font-size: 16px;

    padding: 4px 12px;
    margin-bottom: 4px;
    box-sizing: border-box;
}

nav.sights-navbar div.nav-links.small-screen a:hover {
    background-color: rgba(229, 167, 36, .75);
}
nav.sights-navbar div.nav-links.small-screen a.active {
    background-color: rgba(229, 167, 36, .75);
}

nav.sights-navbar .nav-links.desktop-v2 {
    display: none;
}

@media (min-width: 1024.98px) {
    nav.sights-navbar.landing-page-v2 {
        display: inline-block;
        position: fixed;
        top: 20px;
        right: 12px;
        left: unset;
        height: 0;
        width: auto;
    }
}
nav.sights-navbar.landing-page-v2 .nav-links.desktop {
    display: none;
}


nav.sights-navbar.landing-page-v2 > a {
    display: none;
}


nav.sights-navbar.landing-page-v2 .nav-links.desktop-v2 {
    display: flex;
}

nav.sights-navbar.landing-page-v2 .nav-links.desktop-v2 a {
    text-align: center;
    padding: 8px 24px;

    font-family: "LT Bulletin";
    font-size: 20px;
    
    text-decoration: none;

    color: black;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 50px;

    min-width: 120px;

    margin: 0 12px;
    box-shadow: 0 0 8px rgba(255, 255, 255, .3);
}

nav.sights-navbar.landing-page-v2 .nav-links.desktop-v2 a.active,
nav.sights-navbar.landing-page-v2 .nav-links.desktop-v2 a:hover {
    /* color: #fff; */
    background-color: #FFC547;
}


@media (max-width: 1440.98px) {    
    nav.sights-navbar div.nav-links.desktop {
        display: none;
    }
    nav.sights-navbar div.nav-links.small-screen {
        display: flex;
    }
    
    nav.sights-navbar.landing-page-v2 div.nav-links.small-screen {
        display: none;
    }
    
    nav.sights-navbar .menu-icon {
        display: block;
    }
    nav.sights-navbar.landing-page-v2 .menu-icon {
        display: none;
    }
}

@media (max-width: 1024.98px) {
    nav.sights-navbar.landing-page-v2 .nav-links.desktop-v2 {
        display: none;
    }
    nav.sights-navbar .menu-icon {
        display: block;
    }
    nav.sights-navbar.landing-page-v2 > a {
        display: block;
    }
    
    nav.sights-navbar.landing-page-v2 div.nav-links.small-screen {
        display: flex;
    }
    nav.sights-navbar.landing-page-v2 .menu-icon {
        display: block;
    }
}


@media (max-width: 768.98px) {
    nav.sights-navbar {
        height: 72px;
    } 
    nav.sights-navbar div.nav-links.small-screen {
        display: flex;
    }
    nav.sights-navbar a img.sights-logo {
        margin-left: 12px;
    }
}


@media (max-width: 576.98px) {
    nav.sights-navbar a img.sights-logo {
        margin-left: 8px;
    }
}

@media (max-width: 375.98px) {
    nav.sights-navbar div.nav-links.small-screen {
        top: 84px;
    }
}

@media (max-width: 320.98px) {
    nav.sights-navbar {
        height: 60px;
    }
}