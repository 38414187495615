.content.policy-v2 {
    position: relative;
    width: 100%;
}
.content.policy-v2 .policy-content {
    position: relative;
    
    width: 100%;
    max-width: 1200px;

    margin: auto;

    padding: 60px;
    box-sizing: border-box;
}
.content.policy-v2 .policy-content h2.privacy-title {
    font-size: 42px;
    font-family: 'Poppins';
    font-weight: 600;
    text-align: center;

    /* color: #e5a722; */
    color: #2f2f2f;

    margin: 0;
    margin-bottom: 42px;
}

.content.policy-v2 .policy-content p.privacy-desc {
    font-size: 18px;
    text-align: center;
}


.content.policy-v2 .policy-content div.policy-collection {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1000px;

    padding: 0 40px;
    box-sizing: border-box;

    margin-top: 42px;
}

.content.policy-v2 .policy-content div.policy-collection .policy-item {
    width: 100%;
    margin-bottom: 24px;
}

.content.policy-v2 .policy-content div.policy-collection .policy-item .item-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #fff;
    background-color: #4b80a9;

    height: 60px;
    padding: 0 24px;

    border: none;
    box-sizing: border-box;

    cursor: pointer;

    transition: all 300ms ease-in-out;
}
.content.policy-v2 .policy-content div.policy-collection .policy-item .item-header:hover {
    background-color: #2E4F69;
}

.content.policy-v2 .policy-content div.policy-collection .policy-item .item-header h5 {
    font-size: 24px;

    margin: 0;
}
.content.policy-v2 .policy-content div.policy-collection .policy-item .item-header button {
    border: none;

    font-size: 18px;
    cursor: pointer;
}

.content.policy-v2 .policy-content div.policy-collection .policy-item .item-header img {
    z-index: 100;
    max-height: 12px;
    cursor: pointer;
}

.content.policy-v2 .policy-content div.policy-collection .policy-item .item-body {
    overflow: hidden;
    padding: 0 24px;


    max-height: 0;

    background-color: rgb(240, 240, 240);
    
    transition: all 250ms ease-in-out;
}

.content.policy-v2 .policy-content div.policy-collection .policy-item .item-body.show {
    max-height: unset;
}

.content.policy-v2 .policy-content div.policy-collection .policy-item .item-body p {
    font-size: 16px;
}

.content.policy-v2 .policy-content div.last-section * {
    text-align: justify;
    text-align-last: center;
}
.img-privacy {
    text-align: center;
}
.img-privacy-policy {
    width: 350px;
    height: 500px;
}

@media (max-width: 767.98px) {
    .content.policy-v2 .policy-content div.policy-collection {
        padding: 0 12px;
    }
    .content.policy-v2 .policy-content div.policy-collection .policy-item .item-header h5 {
        font-size: 20px;
    }
    .content.policy-v2 .policy-content div.policy-collection .policy-item .item-header {
        padding: 0 16px;
    }
    .content.policy-v2 .policy-content div.policy-collection .policy-item .item-header img {
        max-height: 10px;
    }
    .img-privacy-policy {
        width: 80%;
        height: 80%;
    }
}
@media (max-width: 576px) {
    .content.policy-v2 .policy-content {
        padding: 60px 42px;
    }
    .img-privacy-policy {
        width: 80%;
        height: 80%;
    }
}

@media (max-width: 575.98px) {
    .content.policy-v2 .policy-content {
        padding: 60px 12px;
    }
    .content.policy-v2 .policy-content div.last-section {
        padding: 0 28px;
        box-sizing: border-box;
    }
    .content.policy-v2 .policy-content h2.privacy-title {
        font-size: 36px;
    }
    .img-privacy-policy {
        width: 80%;
        height: 80%;
    }
}
@media (max-width: 375.98px) {
    .content.policy-v2 .policy-content div.policy-collection .policy-item .item-header {
        padding: 0 10px;
    }
    .content.policy-v2 .policy-content div.policy-collection .policy-item .item-header h5 {
        font-size: 18px;
    }
    .content.policy-v2 .policy-content h2.privacy-title {
        margin-bottom: 28px;
    }
    .content.policy-v2 .policy-content p.privacy-desc {
        padding: 0 20px;
        font-size: 16px;
    }
    .content.policy-v2 .policy-content div.policy-collection .policy-item .item-body {
        padding: 0 16px;
    }
    .img-privacy-policy {
        width: 80%;
        height: 80%;
    }
}
@media (max-width: 360px) {
    .content.policy-v2 .policy-content div.policy-collection .policy-item .item-header img {
        margin-left: 4px;
    }
    .content.policy-v2 .policy-content div.last-section {
        padding: 0 12px;
    }
    .img-privacy-policy {
        width: 80%;
        height: 80%;
    }
}
@media (max-width: 320.98px) {
    .content.policy-v2 .policy-content {
        padding-top: 48px;
    }
    .content.policy-v2 .policy-content h2.privacy-title {
        margin-bottom: 20px;
    }
    .content.policy-v2 .policy-content div.last-section {
        padding: 0 16px;
    }
    .img-privacy-policy {
        width: 80%;
        height: 80%;
    }
}