.content.amani {
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.content.amani main.main,
.content.amani .section2,
.content.amani .section3,
.content.amani .section4 {
    max-width: 1920px;
    margin: auto;
}
.content.amani main.main {
    position: relative;
    height: 100%;
    width: 100%;

    margin: auto;

    overflow: hidden;

    box-sizing: border-box;

    background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/main-sky-bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    min-height: 900px;
    max-height: 1080px;
    /* max-width: 2560px; */
}
.content.amani main.main img.animated-text {
    z-index: 101;
    position: absolute;
    top: 100px;
    right: 4%;

    max-height: 140px;
}

.content.amani main.main img.swoosh-bg {
    z-index: 100;
    position: absolute;
    top: -180px;
    left: 0;
    width: 100%;

    min-width: 1920px;
}

.content.amani main.main div.main-img-container {
    display: inline-block;
    position: absolute;
    bottom: -160px;
    left: 0;

    width: 100%;
    /* transform: translateY(0); */
    transition: all 1s ease-in-out;
}
.content.amani main.main div.main-img-container.hidden {    
    bottom: -60%;
    /* transform: translateY(60%); */
}

.content.amani main.main div.main-img-container img {
    position: relative;
    z-index: 101;
    width: 100%;
}
.content.amani main.main div.main-img-container img.main-img-trees {
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
}


.content.amani main.main h2.main-header {
    position: relative;
    z-index: 120;
    margin: 0;

    font-size: 100px;
    color: #4b80a9;
    margin-top: 60px;
    margin-left: 140px;
    margin-right: auto;

    user-select: none;

    opacity: 1;
    transform: translateX(0);

    transition: all 1200ms ease 200ms;
}

.content.amani main.main h2.main-header.hidden {
    opacity: 0;
    transform: translateX(-40px);
}

.content.amani main.main h2.main-header > span {
    display: inline-block;
    font-size: 72px;
    font-family: "LT Bulletin";
    color: #afb0b0;
    margin-left: 4px;
}

.content.amani div.section2 {
    height: 480px;
    width: 100%;
    
    background-size: cover;
    background-position: center 0%;
    background-repeat: no-repeat;
    background-attachment: fixed;

    overflow: hidden;
}
.content.amani div.section2 .text-content {
    display: flex;
    height: 100%;
    width: 100%;

    /* background-color: rgba(250, 204, 0, .8); */
    /* background-color: rgba(229, 167, 36, .85); */
    background-color: rgba(0, 0, 0, .6);

    padding: 0 90px;
    box-sizing: border-box;

    /* transform: translateX(-100%); */
    /* transition: transform 800ms ease-in-out; */
}

.content.amani div.section2 .text-content.show {
    /* transform: translateX(0); */
}

.content.amani div.section2 .text-content h4 {
    display: inline-block;
    margin: auto;

    font-family: "Poppins";
    font-size: 42px;
    font-weight: 500;

    text-align: center;

    max-width: 1000px;
    line-height: 1em;

    color: #E5A724;
    /* transform: translateY(-20px);
    opacity: 0;
    transition: all 600ms ease-in-out 1000ms; */
}

.content.amani div.section2 .text-content.show h4 {
    /* transform: translateY(0);
    opacity: 1; */
}

.content.amani div.section3 {
    display: flex;
    position: relative;
    min-height: 720px;
    width: 100%;

    /* background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */

    padding: 20px 42px;
    box-sizing: border-box;
}

.content.amani div.section3 div.color-overlay {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    /* background-color: rgba(255, 255, 255, .8); */
}

.content.amani div.section3 div.section-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
    margin: auto;
    width: 100%;
    max-width: 1920px;
}

.content.amani div.section3 div.section-content > div.text-cont {
    position: relative;
    z-index: 21;
    margin: auto;
    margin-left: 42px;
    max-width: 600px;

    padding: 20px 0;
    box-sizing: border-box;

    margin-right: 42px;
}
.content.amani div.section3 div.section-content > div.text-cont h4 {
    font-size: 42px;
    margin: 0;
    margin-bottom: 12px;
}
.content.amani div.section3 div.section-content > div.text-cont h4 span {
    font-family: 'LT Bulletin';
    /* color: #4b80a9; */
}
.content.amani div.section3 div.section-content > div.text-cont p {
    margin: 0;
    font-size: 18px;
    color: #2f2f2f;
}

.content.amani div.section3 div.section-content > div.section-carousel {
    z-index: 50;
    position: relative;
    max-width: 720px;
    width: 100%;
}

.content.amani div.section3 div.section-content > div.section-carousel img {
    border-radius: 6px;
}

.content.amani div.section3 div.section-content > div.section-carousel .carousel.slide .carousel-indicators {
    bottom: -42px;
}

.content.amani div.section3 div.section-content > div.section-carousel .carousel.slide .carousel-caption {
    bottom: 0rem;
}
.content.amani div.section3 div.section-content > div.section-carousel .carousel.slide .carousel-caption h4 {
    display: inline-block;
    color: #fff;
    background-color: #4b80a9;

    border: solid 4px #fff;
    border-radius: 4px;

    font-size: 24px;

    padding: 12px 40px;
    min-width: 200px;

    box-shadow: 0 2px 12px rgba(50, 50, 50 , .75);
    
}
.content.amani div.section3 div.section-content > div.section-carousel .carousel .thumbs-wrapper {
    display: flex;
}
.content.amani div.section3 div.section-content > div.section-carousel .carousel .thumbs {
    display: inline-block;
    margin: 0 auto;
    padding-inline-start: 0;
}

.content.amani div.section3 div.section-content > div.text-cont .details {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    margin-top: 24px;
}

.content.amani div.section3 div.section-content > div.text-cont .details .detail-item {
    display: flex;
    align-items: center;
    width: 280px;
    margin-bottom: 20px;
}
.content.amani div.section3 div.section-content > div.text-cont .details .detail-item img {
    max-width: 32px;
}
.content.amani div.section3 div.section-content > div.text-cont .details .detail-item p {
    font-size: 14px;
    font-weight: 500;
    margin-left: 16px;
    text-align: left;
    text-align-last: left;
}

.content.amani div.section4 {
    display: flex;
    position: relative;
    min-height: 700px;
    width: 100%;
    
    min-height: 100%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    box-sizing: border-box;
    padding: 60px;
}

.content.amani div.section4 .modal-overlay {
    z-index: 160;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
}
.content.amani div.section4 .unit-modal {
    z-index: 200;
    position: fixed;
    top: calc(50% + 24px);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    max-width: 92%;
    max-height: calc(100% - 240px);
    /* width: calc(100% - 40px); */
    box-sizing: border-box;
    border-radius: 8px;

    /* overflow: hidden; */
}

.content.amani div.section4 .unit-modal .modal-img {
    width: 100%;
    height: auto;
}
.content.amani div.section4 .unit-modal .modal-close {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 36px;

    /* padding: 8px; */
    box-sizing: border-box;
    /* border: solid 2px #fff; */
    border-radius: 8px;
    background-color: #4b80a9;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    
}

.content.amani div.section4 .unit-modal .modal-close:hover {
    transform: scale(1.05);
}

.content.amani div.section4 .unit-gallery {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}


.content.amani div.section4 .unit-gallery .unit-control {
    display: flex;
    justify-content: center;
}

.content.amani div.section4 .unit-gallery .unit-control button {
    min-width: 240px;
    border: solid 2px #4b80a9;

    padding: 6px;
    color: #4b80a9;
    background-color: rgba(255, 255, 255, .8);

    cursor: pointer;
}

.content.amani div.section4 .unit-gallery .unit-control button.active {
    background-color: #4b80a9;
    color: #fff;
}

.content.amani div.section4 .unit-gallery .unit-control button.exterior-btn {
    border-right-width: 1px;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
}
.content.amani div.section4 .unit-gallery .unit-control button.interior-btn {
    border-left-width: 1px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
}


.content.amani div.section4 .unit-gallery .unit-cont {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;

    overflow: hidden;
    margin: auto;
    margin-top: 48px;
    margin-bottom: 60px;

    width: 100%;
    max-width: 1620px;
    scroll-behavior: smooth;
}

.content.amani div.section4 .unit-gallery .unit-images {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: row wrap;
    min-width: 100%;
    max-width: 1620px;

    opacity: 1;
    /* transition: all 400ms ease-in-out; */
}
/* .content.amani div.section4 .unit-gallery .unit-images.show {
    opacity: 1;
} */
.content.amani div.section4 .unit-gallery .unit-images.interior {
    overflow-y: auto;
    max-height: 640px;
}

.content.amani div.section4 .unit-gallery .unit-images .img-item {
    position: relative;
    width: 360px;
    height: 240px;
    overflow: hidden;
    /* border: solid 1px #4b80a9; */

    margin: 20px;

    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;

    cursor: pointer;
    transition: background-size 500ms ease, outline 100ms ease-in-out;
}

.content.amani div.section4 .unit-gallery .unit-images .img-item div.color-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 42, 60, 0.42);

    transition: all 400ms ease-in-out;
}

.content.amani div.section4 .unit-gallery .unit-images .img-item:hover {
    background-size: auto 125%;
    outline: solid 6px #4b80a9;
}
.content.amani div.section4 .unit-gallery .unit-images .img-item:hover div.color-overlay {
    opacity: 0;
}


.content.amani div.section5 {
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;

    padding: 60px 40px;
    box-sizing: border-box;
}

.content.amani div.section5 h2 {
    z-index: 120;
    position: relative;

    margin: 0 auto;
    /* margin-bottom: 60px; */

    font-size: 42px;
}

.content.amani div.section5 div.color-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    /* background-color: rgba(255, 255, 255, .8); */
}

.content.amani div.section5 .floor-plan-cont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    position: relative;

    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 72px;
}

.content.amani div.section5 .floor-plan-cont .floor-item {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 12px;
    margin-bottom: 72px;
}
.content.amani div.section5 .floor-plan-cont .floor-item:last-child {
    max-width: 840px;
    margin-bottom: 0;
}

.content.amani div.section5 .floor-plan-cont .floor-item h4 {
    z-index: 51;
    position: relative;
    display: inline-flex;
    align-items: center;
    
    margin: 0;

    font-size: 20px;
    font-family: 'Poppins';

    color: #fff;
    background-color: #4b80a9;

    padding: 8px 24px;
}

.content.amani div.section5 .floor-plan-cont .floor-item h4 img {
    max-height: 28px;
    margin-left: 10px;

    cursor: pointer;
}

.content.amani div.section5 .floor-plan-cont .floor-item .img-cont {
    z-index: 50;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;


    /* border: solid 4px #4b80a9; */
    background-color: #fff;

    padding: 32px 24px;
    padding-bottom: 16px;
    box-sizing: border-box;

    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    overflow: hidden;
}

.content.amani div.section5 .floor-plan-cont .floor-item .img-cont img.floor-plan {
    width: 100%;
    align-self: center;
    max-height: 420px;
}
.content.amani div.section5 .floor-plan-cont .floor-item:last-child .img-cont img.floor-plan {
    width: 100%;
}

.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 12px;
    width: 100%;
    max-width: 240px;
    /* max-height: 420px; */
}
.content.amani div.section5 .floor-plan-cont .floor-item:last-child .img-cont > div {
    max-width: 480px;
}

.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div h5 {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin: 0;
    margin-top: 12px;
}

.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont {
    z-index: 40;
    /* display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-evenly; */

    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    
    margin: 0;

    background-color: rgba(229, 167, 36, 1);

    padding: 20px;
    box-sizing: border-box;

    transform: translateY(100%);    
    transition: transform 600ms ease-in-out;
}
.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont.show {
    transform: translateY(0);  
}

.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont h5 {
    font-size: 24px;
}

.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont .item-list {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    margin-top: 20px;
}
.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont .specs-item {
    display: flex;
    align-items: center;

    /* margin: 0 12px; */
    padding: 0 16px;
    margin-bottom: 12px;
}

.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont .specs-item img {
    max-height: 36px;
    margin-right: 12px;
}


.carousel.carousel-slider .control-arrow,
.carousel .control-prev.control-arrow:before,
.carousel.carousel-slider .control-arrow,
.carousel .control-next.control-arrow:before {
    color: black;
}
.carousel .control-prev.control-arrow:before {
    border-right-color: black;
}
.carousel .control-next.control-arrow:before {
    border-left-color: black;
}

@media (max-width: 1800.98px) {
    .content.amani main.main img.swoosh-bg {
        top: -100px;
    }
    .content.amani main.main div.main-img-container {
        bottom: -60px;
    }
    .content.amani main.main h2.main-header {
        margin-top: 80px;
    }
    .content.amani main.main img.animated-text {
        right: 60px;
    }
}

@media (max-width: 1680.98px) {
    .content.amani main.main {
        min-height: 800px;
    }
    .content.amani main.main img.swoosh-bg {
        min-width: 1440px;
        top: -90px;
    }
    .content.amani main.main div.main-img-container {
        bottom: -72px;
    }
    .content.amani main.main h2.main-header {
        margin-left: 120px;
        margin-top: 60px;
    }
    .content.amani main.main img.animated-text {
        right: 40px;
    }

    .content.amani div.section2 .text-content {
        /* width: 720px; */
        /* padding: 0 72px; */
    }
}
@media (max-width: 1440.98px) {
    .content.amani main.main img.swoosh-bg {
        top: -60px;
    }
    .content.amani main.main div.main-img-container {
        bottom: -20px;
    }
    .content.amani main.main h2.main-header {
        margin-left: 80px;
        margin-top: 48px;
    }
    .content.amani main.main img.animated-text {
        top: 90px;
        right: 20px;
    }

    
    .content.amani div.section2 .text-content {
        /* width: 640px; */
        /* padding: 0 60px; */
    }

    
    .content.amani div.section3 div.section-content > div.section-carousel {
        max-width: 600px;
    }
}

@media (max-width: 1366.98px) {
    .content.amani main.main img.swoosh-bg {
        min-width: 1300px;
        top: -80px;
    }
    .content.amani main.main h2.main-header {
        font-size: 84px;
    }
    .content.amani main.main h2.main-header > span {
        font-size: 60px;
    }
    .content.amani main.main img.animated-text {
        top: 72px;
        max-height: 120px;
    }
    .content.amani main.main div.main-img-container {
        bottom: -42px;
    }


    .content.amani div.section2 .text-content {
        /* width: 600px; */
        /* padding: 0 48px; */
    }
}

@media (max-width: 1280.98px) {
    .content.amani main.main {
        min-height: 680px;
        max-height: 720px;
    }
    .content.amani main.main h2.main-header {
        margin-left: 80px;
        margin-top: 36px;
    }
    
    .content.amani main.main img.animated-text {
        top: 60px;
    }


    .content.amani div.section3 div.section-content {
        flex-direction: column;
    }

    .content.amani div.section3 {
        padding: 60px 72px;
    }
    .content.amani div.section3 div.section-content > div.text-cont {
        width: 100%;
        max-width: 720px;
        margin: 0;
        margin-bottom: 42px;
    }

    .content.amani div.section3 div.section-content > div.section-carousel {
        max-width: 480px;
    }

    .content.amani div.section4 {
        padding: 60px 0;
    }
    .content.amani div.section4 .unit-gallery .unit-cont {
        max-width: 720px;
    }
    .content.amani div.section4 .unit-gallery .unit-images {
        min-width: 720px;
        max-width: 720px;
    }
    .content.amani div.section4 .unit-gallery .unit-images .img-item {
        width: 140px;
        height: 140px;
        margin: 12px;
    }

}


@media (max-width: 1024.98px) {
    .content.amani main.main {
        min-height: 540px;
        max-height: 540px;
    }
    .content.amani main.main img.swoosh-bg {
        min-width: 1024px;
        top: -60px;
    }
    .content.amani main.main h2.main-header {
        font-size: 64px;
        margin-left: 72px;
        margin-top: 36px;
    }
    .content.amani main.main h2.main-header > span {
        font-size: 48px;
    }
    .content.amani main.main img.animated-text {
        max-height: 90px;
    }
    .content.amani main.main div.main-img-container {
        bottom: -24px;
    }

    .content.amani div.section2 {
        /* height: 600px; */
    }
    .content.amani div.section2 .text-content {
        /* width: 420px; */
        /* padding: 0 42px; */
    }
    .content.amani div.section2 .text-content h4 {
        font-size: 48px;
    }

    
    .content.amani div.section3 div.section-content > div.section-carousel {
        max-width: 420px;
    }
    .content.amani div.section3 div.section-content > div.text-cont {
        margin: 0;
        /* padding-right: 42px; */
        /* max-width: 420px; */
    }
    .content.amani div.section3 div.section-content > div.text-cont .details .detail-item {
        /* width: 220px; */
    }
    .content.amani div.section5 .floor-plan-cont {
        flex-direction: column;
        align-items: flex-start;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item {
        margin: 0 auto;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item {
        margin-bottom: 42px;
    }
}


@media (max-width: 768.98px) {
    .content.amani main.main {
        min-height: 480px;
        max-height: 480px;
    }
    .content.amani main.main img.swoosh-bg {
        min-width: 768px;
        top: 0px;
    }
    .content.amani main.main h2.main-header {
        margin-left: 48px;
    }
    .content.amani main.main img.animated-text {
        top: 48px;
    }
    .content.amani div.section3 div.section-content > div.text-cont .details .detail-item {
        /* width: 240px; */
    }

    .content.amani div.section2 {
        /* height: 480px; */
    }
    .content.amani div.section2 .text-content {
        /* width: 340px; */
        /* padding: 0 32px; */
    }
    .content.amani div.section2 .text-content h4 {
        font-size: 40px;
    }
    .content.amani div.section3 div.section-content > div.text-cont .details {
        justify-content: center;
    }



    .content.amani div.section3 {
        padding: 48px 42px;
    }

    .content.amani div.section3 div.section-content {
        flex-direction: column;
    }
    
    .content.amani div.section3 div.section-content > div.text-cont {
        margin: 0;
        padding: 0 24px;
        max-width: 720px;
    }
    .content.amani div.section3 div.section-content > div.text-cont h4 {
        text-align: center;
    }
    .content.amani div.section3 div.section-content > div.text-cont p {
        text-align: justify;
        text-align-last: center;
    }
    .content.amani div.section3 div.section-content > div.section-carousel {
        margin-top: 42px;
        max-width: 548px;
    }


    .content.amani div.section4 .unit-gallery .unit-images {
        justify-content: center;
    }
    .content.amani div.section4 .unit-gallery .unit-cont {
        max-width: 500px;
    }
    .content.amani div.section4 .unit-gallery .unit-images {
        min-width: 500px;
        max-width: 500px;
    }

    .content.amani div.section4 .unit-modal {
        width: 100%;
    }

}

.content.amani div.section3 div.section-content > div.text-cont small {
    display: block;
    text-align: left;
    margin-top: 20px;
}


@media (max-width: 576.98px) {
    .content.amani div.section3 div.section-content > div.text-cont .details .detail-item p {
        text-align: center;
        text-align-last: center;
    }
    .content.amani div.section3 div.section-content > div.text-cont small {
        text-align: center;
    }
    
    .content.amani main.main {
        display: flex;
        max-height: unset;
        min-height: 600px;
    }
    .content.amani main.main img.swoosh-bg {
        min-width: unset;
        top: -60px;
    }
    
    .content.amani main.main h2.main-header {
        margin: 0 auto;
        margin-top: 60px;
    }

    .content.amani main.main img.animated-text {
        top: 148px;
        right: 50%;
        transform: translateX(50%);
    }
    .content.amani main.main div.main-img-container {
        z-index: 120;
        min-width: 1100px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
    }
    .content.amani main.main div.main-img-container.hidden {    
        bottom: -32%;
    }


    
    .content.amani div.section2 {
        height: 360px;
        background-position: center;
    }
    .content.amani div.section2 .text-content {
        width: 100%;
        padding: 0 32px;
    }
    .content.amani div.section2 .text-content h4 {
        font-size: 32px;
        text-align: center;
        line-height: 1em;
    }

    
    
    .content.amani div.section3 {
        padding: 60px 12px;
    }
    .content.amani div.section3 div.section-content > div.text-cont {
        padding: 0 24px;
    }
    .content.amani div.section3 div.section-content > div.text-cont h4 {
        font-size: 36px;
    }
    .content.amani div.section3 div.section-content > div.text-cont p {
        font-size: 16px;
    }
    .content.amani div.section3 div.section-content > div.text-cont .details {
        justify-content: center;
    }
    
    .content.amani div.section3 div.section-content > div.text-cont .details .detail-item {
        width: auto;
        margin: 12px;
    }
    
    .content.amani div.section3 div.section-content > div.text-cont .details .detail-item > p { 
        /* text-align: left;
        text-align-last: left; */
        font-size: 14px;
    }
    .content.amani div.section3 div.section-content > div.text-cont .details .detail-item img {
        max-width: 24px;
    } 

    
    .content.amani div.section4 {
        padding: 0;
        padding-top: 60px;
    }
    .content.amani div.section4 .unit-gallery .unit-cont {
        max-width: 360px;
    }
    .content.amani div.section4 .unit-gallery .unit-images {
        min-width: 360px;
        max-width: 360px;
    }

    .content.amani div.section4 .unit-gallery .unit-control button {
        min-width: 140px;
    }


    .content.amani div.section5 {
        padding: 60px 20px;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont {
        padding: 24px 4px;
    }
    .content.amani div.section5 h2 {
        font-size: 36px;
    }

    
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont {
        /* flex-wrap: wrap;
        justify-content: center; */
        flex-direction: column;
        align-items: center;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item h4 {
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
        font-size: 20px;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div {
        width: 180px;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item:last-child .img-cont > div {
        width: auto;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div:first-child {
        margin-bottom: 42px;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont .specs-item img {
        max-height: 28px;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont .specs-item p {
        font-size: 14px;
    }

    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont {
        transform: translateY(0) translateX(100%);
        /* height: auto;
        min-height: 75%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont .item-list {
        /* margin: auto; */
        margin-top: 20px;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont h5 {
        margin-top: auto;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont.show {
        transform: translateY(0) translateX(0);
    }
    .content.amani div.section4 .unit-gallery .unit-images.interior {
        overflow-y: scroll;
        max-height: unset;
    }
}

@media (max-width: 375.98px) {
    .content.amani main.main img.swoosh-bg {
        min-width: 480px;
        top: -140px;
        left: 60%;
        transform: translateX(-50%);
    }
    
    .content.amani div.section2 {
        background-position: center;
    }
    .content.amani div.section5 {
        padding: 60px 16px;
    }
}
@media (max-width: 374.98px) {
    .content.amani main.main img.animated-text {
        max-height: 72px;
    }
    .content.amani div.section3 div.section-content > div.text-cont {
        padding: 0 20px;
    }
    .content.amani div.section2 {
        background-position: center;
    }


    .content.amani div.section4 .unit-gallery .unit-cont {
        max-width: 300px;
    }
    .content.amani div.section4 .unit-gallery .unit-images {
        min-width: 300px;
        max-width: 300px;
    }
    .content.amani div.section4 .unit-gallery .unit-images .img-item {
        width: 120px;
        height: 120px;
        margin: 12px;
    }

}
@media (max-width: 320.98px) {
    .content.amani div.section3 div.section-content > div.text-cont {
        padding: 0;
    }
    .content.amani div.section3 div.section-content > div.text-cont > p {
        padding: 0 24px;
    }
    .content.amani div.section3 div.section-content > div.text-cont h4 {
        padding: 0 12px;
    }
    .content.amani div.section3 div.section-content > div.text-cont .details .detail-item p {
        margin-left: 12px;
    }
}



.content.amani div.main-v2 {
    position: relative;
    display: flex;
    align-self: flex-start;
    width: 100%;
    max-width: 1920px;
    height: calc(100vh - 90px);
    max-height: 1080px;

    margin: auto;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.content.amani div.main-v2 div.text-content {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 41;
    align-self: flex-start;
    height: 100%;

    box-sizing: border-box;
    width: 100%;
    max-width: 720px;

    background-color: rgba(255, 255, 255, 1);
    background-size: contain;
    background-repeat: repeat-x;
    /* overflow: hidden; */

    transition: all 400ms ease-in-out;
}
.content.amani div.main-v2 div.text-content.hide {
    max-width: 0;
}
.content.amani div.main-v2 div.text-content img.cont-toggle {
    position: absolute;
    top: 20%;
    right: 2px;
    max-width: 42px;

    transform: translateX(100%);
    cursor: pointer;
}
.content.amani div.main-v2 div.text-content > div {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.content.amani div.main-v2 div.text-content > div > div {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    padding: 40px 60px;
    box-sizing: border-box;
    min-width: 640px;
    margin-left: 12px;
}
.content.amani div.main-v2 div.text-content .text-swoosh {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 42%;

    width: 1200px;
    /* transform: translateX(-50%); */

}

.content.amani div.main-v2 div.img-content {
    height: 100%;
    width: 100%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}
.content.amani div.main-v2 h2 {
    z-index: 22;
    font-size: 120px;
    line-height: .8em;
    color: #4b80a9;
    margin: 0;
}
.content.amani div.main-v2 h2 span {
    font-size: .54em;
    font-weight: 500;
    color: #afb0b0;

    margin-left: -12px;
}
.content.amani div.main-v2 p {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
    color: #2f2f2f;

    margin-top: 4px;
}
.content.amani div.main-v2 p span {
    color: #E5A724;
    font-weight: 600;
    font-size: 28px;
    font-style: italic;
} 

.content.amani div.main-v2 small {
    display: block;
    font-size: 16px;
    text-align: center;
    margin-top: 42px;
    margin: auto;
    margin-bottom: 42px;
    min-width: 640px;
}

/* .content.amani div.main-v2 div.color-overlay {
    z-index: 40;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(160deg, rgba(255, 255, 255, .9) 28%, rgba(255, 255, 255, .0) 60%);
} */

@media (max-width: 1600px) {
    .content.amani div.main-v2 div.text-content {
        max-width: 640px;
    }
    .content.amani div.main-v2 h2 {
        font-size: 112px;
    }
}

@media (max-width: 1366.98px) {
    .content.amani div.main-v2 div.text-content {
        max-width: 540px;
    }
    .content.amani div.main-v2 h2 {
        font-size: 100px;
    }
    .content.amani div.main-v2 div.text-content > div > div {
        min-width: 540px;
        padding: 40px;
    }
    .content.amani div.main-v2 small {
        min-width: 540px;
    }
}
@media (max-width: 1024.98px) {
    .content.amani div.main-v2 div.text-content {
        max-width: 480px;
    }
    .content.amani div.main-v2 h2 {
        font-size: 90px;
    }
    .content.amani div.main-v2 p {
        font-size: 24px;
    }
    .content.amani div.main-v2 div.text-content > div > div {
        min-width: 480px;
        padding: 40px 32px;
    }
    .content.amani div.main-v2 small {
        min-width: 480px;
    }
}
@media (min-width: 768px) {
    .content.amani div.main-v2 div.text-content img.mobile-img {
        display: none;
    }
}
@media (max-width: 767.98px) {
    .content.amani div.main-v2 div.text-content {
        max-width: unset;
    }
    .content.amani div.main-v2 div.img-content,
    .content.amani div.main-v2 div.text-content img.cont-toggle {
        display: none;
    }
    
    .content.amani div.main-v2 h2 {
        font-size: 100px;
        text-align: center;
    }
    .content.amani div.main-v2 p {
        font-size: 24px;
        text-align: center;
    }
    .content.amani div.main-v2 div.text-content > div > div,
    .content.amani div.main-v2 small {
        min-width: unset;
    }
    .content.amani div.main-v2 div.text-content > div > div {
        margin-left: 0;
        margin-bottom: 32px;
    }
    .content.amani div.main-v2 div.text-content .text-swoosh {
        top: 24%;
        width: 1600px;
    }
    
    .content.amani div.main-v2 div.text-content img.mobile-img {
        width: 90%;
        margin: 0 auto;
        border-radius: 6px;
    }
    .content.amani div.main-v2 {
        height: calc(100vh - 72px);
    }
    .content.amani div.main-v2 {
        /* background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/amani-series-v2.jpg'); */
        background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-5.jpg');
    }
    .content.amani div.main-v2 div.text-content {
        background-color: rgba(255, 255, 255, .82);
        background-image: none !important;
    }
    .content.amani div.main-v2 div.text-content img.mobile-img {
        display: none;
        width: 100%;
        border-radius: 0;
    }
    .content.amani div.main-v2 div.text-content .text-swoosh {
        display: none;
        top: 32%;
        width: 1200px;
    }
    .content.amani div.main-v2 p {
        margin-top: 16px;
    }
    .content.amani div.main-v2 h2 span {
        color: #4f4f4f;
    }
}
@media (max-width: 576.98px) {
    .content.amani div.main-v2 h2 {
        font-size: 84px;
    }
    .content.amani div.main-v2 h2 span {
        margin-left: -8px;
    }
    .content.amani div.main-v2 div.text-content > div > div {
        padding: 40px 0;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont .specs-item {
        margin-bottom: 20px;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item:nth-child(2) .img-cont > div.specs-cont .specs-item {
        margin-bottom: 36px;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item:nth-child(2) .img-cont > div.specs-cont .item-list {
        margin-top: 42px;
    }
}
@media (max-width: 375.98px) {
    .content.amani div.main-v2 h2 {
        font-size: 72px;
    }
    .content.amani div.main-v2 p {
        font-size: 20px;
    }
    .content.amani div.main-v2 p span {
        font-size: 24px;
    }
}
@media (max-width: 375.98px) {
    .content.amani div.main-v2 h2 {
        font-size: 64px;
    }
    .content.amani div.main-v2 p {
        font-size: 18px;
    }
    .content.amani div.main-v2 p span {
        font-size: 22px;
    }
}
.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont div.close-specs {
    display: flex;
    width: 100%;

    margin: auto;
    margin-top: 24px;
}
.content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont div.close-specs span {
    margin: auto;
    font-weight: 300;

    border: solid 1px black;

    padding: 4px 24px;
    border-radius: 4px;
}
@media (min-width: 576px) {
    .content.amani div.section5 .floor-plan-cont .floor-item .img-cont > div.specs-cont div.close-specs {
        display: none;
    }
}


@media (max-width: 320.98px) {
    .content.amani div.section2 .text-content h4 {
        font-size: 28px;
    }
    .content.amani div.section3 {
        padding-top: 42px;
    }
    .content.amani div.section3 div.section-content > div.text-cont h4 {
        font-size: 32px;
    }
    .content.amani div.section3 div.section-content > div.text-cont > p {
        padding: 0 18px;
    }
    .content.amani div.section5 .floor-plan-cont .floor-item h4 {
        font-size: 18px;
        padding: 8px 12px;
    }
    .content.amani div.section5 {
        padding-top: 36px;
    }
    .content.amani div.section5 .floor-plan-cont {
        margin-top: 42px;
    }
    .content.amani {
        padding-bottom: 0;
    }
}


.modal-360-btn {
    margin-top: 12px;
    padding: 4px 12px;
    font-size: 15px;
    max-width: 280px;
    color: #fff;
    background-color: #4b80a9;
    border: solid 3px #4b80a9;    
    cursor: pointer;
    transition: all 240ms ease-in-out;
}
.modal-360-btn:hover {
    color: #4b80a9;
    background-color: transparent;
}

.modal-360 {
    z-index: 1000;
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    transition: all 400ms ease-in-out;
}
.modal-360.hide {
    z-index: 0;
    opacity: 0;
}
.modal-360 > div {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 12px;
    background-color: #fff;
    transition: all 400ms ease-in-out;
}
.modal-360.hide > div {
    transform: translateY(40%);
}
.modal-360 > div .links {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    margin: 0 auto;
}
.modal-360 > div .links a {
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    border: solid 2px #4b80a9;
    background-color: #4b80a9;
    margin-bottom: 12px;
    padding: 6px;
}
.modal-360 > div .links a:hover {
    color: #4b80a9;
    background-color: transparent;
}
.modal-360 > div .modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #4b80a9;
    padding: 8px 16px;
}
.modal-360 > div .modal-header h4 {
    margin: 0;
    text-align: left;
    font-size: 24px;
    color: #fff;
}
.modal-360 > div .modal-header i {
    margin-left: auto;
    transform: scale(1.5);
    cursor: pointer;
    color: #fff;
}

@media (max-width: 576.98px) {
    .modal-360-btn {
        margin: 0 auto;
        margin-top: 16px;
    }
}