.content.vicinity-map {
    position: relative;
    display: flex;

    width: 100%;
    /* padding-bottom: 120px; */
    box-sizing: border-box;
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.content.vicinity-map div.main {
    /* display: flex; */
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
}
.content.vicinity-map div.main .text-content {
    display: flex;

    width: 100%;
    /* max-width: 720px; */

    height: 100%;
    max-height: 600px;

    box-sizing: border-box;
    padding: 0 100px;

    background-color: rgba(255,255,255,1);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.content.vicinity-map div.main .text-content > div {
    margin: auto;
    max-width: 840px;
}
.content.vicinity-map div.main .text-content > div * {
    text-align: center;
}
.content.vicinity-map div.main .text-content h2 {
    font-size: 60px;
    font-family: 'Poppins';
    font-weight: 600;

    color: #e5a722;
    margin: 0;
}
.content.vicinity-map div.main .text-content p {
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    margin-top: 16px;
}
.content.vicinity-map div.main .text-content > div div.instructions {
    display: flex;
    flex-direction: column;
    margin-top: 42px;
}
.content.vicinity-map div.main .text-content > div div.instructions small {
    font-style: italic;
    font-weight: 300;
}

.content.vicinity-map div.main .map-content {
    display: flex;
    align-items: center;
    margin: auto;
    /* margin-left: 60px; */
    /* padding: 60px 0; */
    min-height: 100%;
    box-sizing: border-box;
}
.content.vicinity-map div.main .map-content .establishment-toggler {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    min-width: 240px;
    padding: 0 16px;
    box-sizing: border-box;
}

.content.vicinity-map div.main .map-content .establishment-toggler h4.toggler-title {
    font-size: 24px;
    font-family: 'Poppins';
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    
    /* color: #e5a722; */

    margin: 0;
    margin-bottom: 24px;
}

.content.vicinity-map div.main .map-content .establishment-toggler-v2 {
    z-index: 100;
    position: absolute;

    display: flex;

    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    /* background-color: rgba(0, 0, 0, .6);
    border-radius: 10px; */
}

.content.vicinity-map div.main .map-content .establishment-toggler-v2 img.toggler {
    max-width: 54px;
    margin: 8px;

    border: solid 4px transparent;
    border-radius: 10px;

    /* padding: 4px; */
    overflow: hidden;
    cursor: pointer;
}

.content.vicinity-map div.main .map-content .establishment-toggler-v2 img.toggler.active {
    border-color: #fff;
}

.content.vicinity-map div.main .map-content .establishment-toggler button {
    margin-bottom: 12px;
    background-color: #e5a722;
    color: #fff;
    font-size: 16px;

    padding: 8px 24px;

    border: solid 2px #e5a722;
    border-radius: 40px;

    cursor: pointer;
}
.content.vicinity-map div.main .map-content .establishment-toggler button:active {

}
.content.vicinity-map div.main .map-content .establishment-toggler button.active {
    border-color: #4B80A9;
    background-color: #4B80A9;
    color: #fff;
}

.content.vicinity-map div.main .map-container {
    position: relative;
    margin: auto;

    width: 1280px;
    height: 720px;
}
.content.vicinity-map div.main .map-container .map-control {
    z-index: 20;
    position: absolute;
    top: 12px;
    left: 12px;
}

.content.vicinity-map div.main .map-container .map-control .zoom-btn {
    max-width: 36px;
    margin: 0 4px;

    cursor: pointer;
}
.content.vicinity-map div.main .map-container .map-control .zoom-btn:active {
    transform: scale(.9);
}

.content.vicinity-map div.main .map-container .map-control .zoom-btn.disabled {
    cursor: auto;
}
.content.vicinity-map div.main .map-container .map-control .zoom-btn.disabled:active {
    transform: none;
}
.content.vicinity-map div.main div.map {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow: hidden;

    margin: auto;
    /* margin-right: 4%; */
}

.content.vicinity-map div.main div.map img {
    transition: all 400ms ease-in-out;
}

.content.vicinity-map div.main div.map img.map-main {
    z-index: 0;
    max-width: 1280px;
}
.content.vicinity-map div.main div.map img.establishments {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    max-width: 1280px;

    opacity: 0;
    transform: translateY(-20px);
}

.content.vicinity-map div.main div.map img.establishments.show {
    opacity: 1;
    transform: translateY(0);
}


.content.vicinity-map div.main div.map.mobile {
    display: none;
}
.close-reminder { 
    display: none;
}

@media (max-width: 1919.98px) {
    .content.vicinity-map div.main .text-content > div {
        /* margin: 0; */
    }
    .content.vicinity-map div.main .text-content h2,
    .content.vicinity-map div.main .text-content p,
    .content.vicinity-map div.main .text-content > div div.instructions small {
        text-align: center;
    }

    .content.vicinity-map div.main .text-content p {
        max-width: 900px;
        margin: auto;

        padding: 0 24px;
    }

    .content.vicinity-map div.main .map-content {
        min-height: 100%;
        /* padding: 40px; */
        box-sizing: border-box;
    }

    .content.vicinity-map div.main .map-container {
        position: relative;
        margin: auto;
        /* width: 1280px;
        height: 720px; */

        /* width: 1152px;
        height: 720px; */
    }
}
@media (max-width: 1680px) {
    .content.vicinity-map div.main .map-container {
        width: 1024px;
        height: 576px;
    }
    .content.vicinity-map div.main div.map img.map-main, .content.vicinity-map div.main div.map img.establishments {
        max-width: 1024px;
    }
}
@media(max-width: 1440px) {
    .content.vicinity-map div.main .map-content {
        margin-left: auto;
    }
    .content.vicinity-map div.main .map-container {
        width: 800px;
        height: 450px;
    }
    .content.vicinity-map div.main div.map img.map-main, .content.vicinity-map div.main div.map img.establishments {
        max-width: 800px;
    }
}
@media (max-width: 1280.98px) {
    .content.vicinity-map div.main .map-container {
    }   
}
.content.vicinity-map div.main .map-container.mobile {
    width: 900px;
    height: 600px;
}
@media (min-width: 1024.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        display: none;
    }
}
@media (max-width: 1024.98px) {
    .content.vicinity-map div.main .map-content {
        padding: 0px;
        min-height: calc(100vh - 90px);
    }
    .content.vicinity-map div.main .map-container.desktop {
        display: none;
    }
}


.content.vicinity-map div.main .map-container.mobile div.map img.map-main {
    max-width: 2160px;
}
.content.vicinity-map div.main .map-container.mobile div.map img.establishments {
    max-width: 2160px;
}
@media (min-width: 1024.98px) {
    .content.vicinity-map div.main .map-content .establishment-toggler-v2.mobile {
        display: none;
    }
}
@media (max-width: 1024.98px) {
    .content.vicinity-map div.main .map-content .establishment-toggler.desktop {
        display: none;
    }
    .content.vicinity-map div.main .map-container.mobile {
        width: 100%;
        height: 100%;
        max-height: 100%;
        /* max-height: calc(100vh - 72px); */
    }
    .content.vicinity-map div.main .text-content {
        position: absolute;
        top: 0;
        left: 0;

        min-height: calc(100vh - 72px);
        padding: 0 40px;

        justify-content: center;

        z-index: 120;

        background-image: none !important;
        background-color: rgba(255,255,255,.9);
    }
    .content.vicinity-map div.main .text-content > div {
        margin: auto;
    }
    .content.vicinity-map div.main .map-content .establishment-toggler-v2 {
        position: fixed;   
    }
    .content.vicinity-map div.main div.map {
        max-height: calc(100vh - 90px);
    }
}

/* @media (orientation: portrait) and (max-width: 768.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 720px;
        height: 900px;
    }
}

@media (orientation: landscape) and (max-width: 960.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 840px;
        height: 420px;
    }
}
@media (orientation: portrait) and (max-width: 720.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 576px;
        height: 1024px;
    }
} */
/* @media (orientation: portrait) and (max-width: 640.98px) and (min-height: 1135.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 560px;
        height: 1000px;
    }
}
@media (orientation: portrait) and (max-width: 640.98px) and (min-height: 1023.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 504px;
        height: 896px;
    }
}
@media (orientation: portrait) and (max-width: 640.98px) and (min-height: 800px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 525px;
        height: 700px;
    }
} */

@media (max-width: 768.98px) {    
    .content.vicinity-map div.main .map-content {
        min-height: calc(100vh - 72px);
    }
    .content.vicinity-map div.main div.map {
        max-height: calc(100vh - 72px);
    }
    
}

@media (max-width: 576px) {
    .content.vicinity-map div.main .map-container.mobile div.map img.map-main {
        max-width: 1440px;
    }
    .content.vicinity-map div.main .map-container.mobile div.map img.establishments {
        max-width: 1440px;
    }
    .content.vicinity-map div.main .text-content h2 {
        line-height: 1.25em;

        margin-bottom: 24px;
    }
    
    .content.vicinity-map div.main .text-content h2 {
        font-size: 42px;
    }
    .content.vicinity-map div.main .text-content p {
        padding: 0;
    }
    .content.vicinity-map div.main .text-content p {
        font-size: 18px;
    }
    .close-reminder { 
        display: block;
    }
    

    .content.vicinity-map div.main .map-content .establishment-toggler-v2 img.toggler {
        max-width: 60px;
        margin: 4px;
    }
}


/* @media (orientation: portrait) and (max-width: 540.98px) and (min-height: 960px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 480px;
        height: 854px;
    }
}
@media (orientation: portrait) and (max-width: 480.98px) and (max-height: 800.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 460px;
        height: 700px;
    }
}
@media (orientation: portrait) and (max-width: 425.98px) and (max-height: 896.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 400px;
        height: 710px;
    }
}


@media (orientation: portrait) and (max-width: 375.98px) and (max-height: 812.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 360px;
        height: 640px;
    }
}
@media (orientation: portrait) and (max-width: 375.98px) and (max-height: 667.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 360px;
        height: 540px;
    }
}

@media (orientation: portrait) and (max-width: 360.98px) and (max-height: 640.98px) {
    .content.vicinity-map div.main .map-container.mobile {
        width: 320px;
        height: 540px;
    }
}
@media (orientation: portrait) and (max-width: 320.98px) {
    .content.vicinity-map div.main .text-content {
        padding: 0 24px;
    }
    .content.vicinity-map div.main .text-content h2 {
        font-size: 32px;
    }
    .content.vicinity-map div.main .text-content p {
        font-size: 14px;
        padding: 0;
    }
    .content.vicinity-map div.main .text-content > div div.instructions small {
        font-size: 11px;
    }
    .content.vicinity-map div.main .map-container.mobile {
        width: 300px;
        height: 480px;
    }

    .content.vicinity-map div.main .map-content .establishment-toggler-v2 img.toggler {
        max-width: 48px;
        margin: 4px;
    }
} */

@media (max-height: 720px) {
    .content.vicinity-map div.main .map-container.mobile div.map img.map-main {
        max-width: 1280px;
    }
    .content.vicinity-map div.main .map-container.mobile div.map img.establishments {
        max-width: 1280px;
    }
}

@media (max-width: 320.98px) {
    .content.vicinity-map div.main .text-content {
        padding: 0 24px;
        min-height: calc(100vh - 60px);
    }
    .content.vicinity-map div.main .text-content h2 {
        font-size: 36px;
        margin-bottom: 16px;
    }
    .content.vicinity-map div.main .text-content p {
        font-size: 16px;
    }
    .content.vicinity-map div.main .text-content > div div.instructions {
        margin-top: 24px;
    }
    .content.vicinity-map div.main .map-content {
        min-height: calc(100vh - 60px);
    }
    .content.vicinity-map div.main div.map {
        max-height: calc(100vh - 60px);
    }
    .content.vicinity-map div.main .map-content .establishment-toggler-v2 img.toggler {
        max-width: 48px;
    }
    .content.vicinity-map div.main .map-content .establishment-toggler-v2 {
        bottom: 28px;
    }
}