.blob {
    display: block;
    opacity: 0;
    z-index: 101;
    position: absolute;

	/* background: #fff; */
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/landing/house-icon-v2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
	border-radius: 50%;
	margin: 10px;
	height: 42px;
	width: 42px;


	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;

    transition: opacity 1200ms ease-in-out 400ms;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(55, 145, 68, 0.7); */
		/* box-shadow: 0 0 0 0 rgba(186, 155, 106, 1); */
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 18px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@media (max-width: 768px) {
    .blob {
        height: 28px;
        width: 28px;
    }
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            /* box-shadow: 0 0 0 0 rgba(55, 145, 68, 0.7); */
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }
    
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
        }
    
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
}
@media (max-width: 576px) {
    .blob {
        height: 12px;
        width: 12px;
    }
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            /* box-shadow: 0 0 0 0 rgba(55, 145, 68, 0.7); */
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }
    
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 12px rgba(0, 0, 0, 0);
        }
    
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
}