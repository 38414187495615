
.content .book-tripping-modal {
    z-index: 5000;
    position: fixed;
    right: 32px;
    bottom: 20px;

    border: solid 0px #4b80a9;
    background-color: #fff;


    width: 100%;
    max-width: 420px;

    height: 100vh;
    max-height: 0;
    overflow: hidden;
    box-sizing: border-box;

    transform: translateY(0);

    transition: transform 600ms ease-in-out, max-height 600ms ease-in-out, border-width 600ms ease-in-out;
}
.content .book-tripping-modal.show {
    border-width: 6px;
    max-height: 600px;
    transform: translateY(-24px);
}
.content .book-tripping-modal * {
    opacity: 0;
    transition: opacity 400ms ease-in-out 600ms;
}
.content .book-tripping-modal.show * {
    opacity: 1;
}

.content .book-tripping-modal .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-right: 4px;
}
.content .book-tripping-modal .modal-header h4 {
    font-size: 20px;
    margin: 0;

    color: #fff;
    background-color: #4b80a9;
    
    padding: 12px;
    /* border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; */
    text-align: center;
    min-width: 120px;
}
.content .book-tripping-modal .modal-header i {
    cursor: pointer;

    color: #4b80a9;
    border: solid 2px #4b80a9;
    background-color: transparent;

    padding: 2px 6px;
    padding-bottom: 0px;

    border-radius: 6px;

    font-size: 1.15em;

    transition: border-radius 400ms ease-in-out;
}
.content .book-tripping-modal .modal-header i:hover {
    color: #fff;
    background-color: #4b80a9;

    border-radius: 28px;
}


.content .book-tripping-modal .modal-content {
    margin: 0 6px;
    padding: 0 12px;
    box-sizing: border-box;

    max-height: calc(100% - 80px);
    padding-top: 8px;
    padding-bottom: 8px;
    box-sizing: border-box;

    overflow-y: auto;
    scroll-behavior: smooth;
}
.content .book-tripping-modal .modal-content::-webkit-scrollbar {
  width: 4px;
}
.content .book-tripping-modal .modal-content form {
    width: 100%;
}
.content .book-tripping-modal .modal-content form .input-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    width: 100%;

    padding: 0 8px;
    box-sizing: border-box;
}
.content .book-tripping-modal .modal-content form .input-item label {
    font-size: 14px;
    margin-bottom: 6px;
}
.content .book-tripping-modal .modal-content form .input-item input,
.content .book-tripping-modal .modal-content form .input-item select {
    margin: 0;
    padding: 6px 8px;

    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    
    height: 36px;
}

.content .book-tripping-modal .modal-content form .input-item textarea {
    resize: none;
}

.content .book-tripping-modal .modal-content form .input-group {
    display: flex;
    width: 100%;

}


.content .book-tripping-modal .modal-content form .input-item button {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;

    border: solid 2px #003262;
    border-radius: 100px;
    color: #003262;

    background-color: transparent;

    padding: 8px;
    box-sizing: border-box;
    
    cursor: pointer;
}
.content .book-tripping-modal .modal-content form .input-item button:hover {
    color: #fff;
    background-color: #003262;
}
.content .book-tripping-modal .modal-content form .input-item button:disabled {
    border-color: gray;
    color: gray;
    background-color: transparent;
    cursor: default;
}

.content .book-tripping-modal .recaptcha {
    padding: 0 12px;
    box-sizing: border-box;
    
    margin-bottom: 12px;
}
.content .book-tripping-modal .recaptcha > div > div > div  {
    margin: auto;
}
.content .book-tripping-modal .recaptcha > div > div > div > div { 
    display: flex;
}


@media (max-width: 576px) {
    .content .book-tripping-modal {
        left: 0;
        right: 0;
        bottom: 0;

        margin: auto;
    }
    .content .book-tripping-modal.show {
        max-height: 720px;
    }
}
@media (max-width: 425px) {
    .content .book-tripping-modal {
        bottom: 0;
        transform: translateY(24px);
    }
    .content .book-tripping-modal.show {
        transform: translateY(0);
        max-height: calc(100vh - 72px);
    }
}
@media (max-width: 374.98px) {
    .content .book-tripping-modal .modal-content form .input-group {
        flex-direction: column;
    }
    .content .book-tripping-modal .recaptcha {
        padding: 0;
        margin-left: -16px;
        transform: scale(.8);
    }
}
@media (max-width: 320.98px) {
    .content .book-tripping-modal.show {
        max-height: calc(100vh - 60px);
        border-width: 0px;
    }
}