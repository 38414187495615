.content.amenities {
    position: relative;
    
    width: 100%;
    max-width: 2560px;

    margin-left: auto;
    margin-right: auto;

    overflow: hidden;
    padding-bottom: 0;
}

.content.amenities .amenity-control {
    z-index: 100;
    position: absolute;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
}

.content.amenities .amenity-control-mobile {
    display: none;
}
.content.amenities .amenity-control-mobile select {
    color: #2f2f2f;
    font-size: 16px;
    text-align: center;
    padding: 6px 12px;

    border-radius: 4px;
}


.content.amenities .amenity-control button.btn-control {
    font-size: 16px;
    font-weight: 500;

    border: solid 2px ;
    border-radius: 42px;

    padding: 6px 24px;
    
    background-color: transparent;
    color: #fff;
    margin: 0 8px;
    cursor: pointer;
}
.content.amenities .amenity-control button.btn-control.active {
    border-color: #E5A724;
    background-color: #E5A724;
    color: #fff;
}

.content.amenities .amenities-cont {
    display: flex;
    align-items: flex-start;

    width: 100%;
    height: 100%;
    overflow: hidden;

    scroll-behavior: smooth;
}
.content.amenities .amenity-item {
    z-index: 90;
    position: relative;

    display: flex;

    min-width: 100%;
    height: 100%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
}


.content.amenities .amenity-item .amenity-text-content {
    /* background-color: rgba(250, 250, 250, .8); */
    align-self: flex-start;
    
    margin: auto;
    /* margin-left: 20px; */
    max-width: 720px;

    box-sizing: border-box;
    padding: 40px;

    border-radius: 12px;
}
.content.amenities .amenity-item .amenity-text-content h4 {
    font-size: 54px;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 0;
    line-height: 1em;
    /* text-transform: uppercase; */
}
.content.amenities .amenity-item:nth-child(even) .amenity-text-content h4 {
    color: #48a6d3;
}
.content.amenities .amenity-item:nth-child(odd) .amenity-text-content h4 {
    color: #E5A724;
}
.content.amenities .amenity-item .amenity-text-content span {
    font-size: 24px;
    font-weight: 300;
    /* color: #2f2f2f; */
    color: #fff;
}
.content.amenities .amenity-item .amenity-text-content p {
    font-size: 20px;
    font-weight: 300;

    /* color: #2f2f2f; */
    color: #fff;

    margin: 0;
    margin-top: 16px;
    text-align: left;
}
.content.amenities .amenity-item .amenity-text-content img.amenity-img {
    display: none;
    width: 100%;
    margin-top: 16px;

    border-radius: 2px;
    /* border: solid 1px rgba(0, 0, 0, .5); */
}

.content.amenities .amenity-item .amenity-img-content {
    display: flex;
    margin: auto;
    margin-right: 100px;
    align-self: flex-start;

    box-sizing: border-box;
}
.content.amenities .amenity-item .amenity-img-content img.amenity-img {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    border-radius: 8px;
    /* box-shadow: 0 4px 12px rgba(255, 255, 255, .3); */
}
.content.amenities .amenity-item .amenity-text-content img.amenity-img-hall {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    border-radius: 8px;
}
.content.amenities .amenity-item .amenity-text-content.amenity-amani {
    margin-top: 120px;
    margin-left: auto;
    margin-right: 20px;
}
.content.amenities .amenity-item .amenity-text-content.amenity-alora {
    margin-top: 120px;
    margin-right: auto;
    margin-left: 20px;
}

.content.amenities .amenity-item .amenity-text-content.amenity-amani h4 {
    text-align: center;
    margin-bottom: 20px;
}
.content.amenities .amenity-item .amenity-text-content.amenity-alora h4 {
    text-align: center;
    color: #E5A724;
    margin-bottom: 20px;
}
.content.amenities .amenity-item .amenity-text-content.amenity-amani p,
.content.amenities .amenity-item .amenity-text-content.amenity-alora p {
    text-align: justify;
    text-align-last: center;
}

@media (max-width: 1600.98px) {
    .content.amenities .amenity-item .amenity-text-content {
        margin-left: 20px;
    }
    .content.amenities .amenity-item .amenity-text-content.amenity-amani,
    .content.amenities .amenity-item .amenity-text-content.amenity-alora {
        max-width: 640px;
        margin-top: 80px;
    }
    .content.amenities .amenity-item .amenity-text-content h4 {
        font-size: 48px;
    }
}
@media (max-width: 1440.98px) {
    .content.amenities .amenity-item .amenity-text-content {
        margin-left: 20px;
        max-width: 600px;
    }
    .content.amenities .amenity-item .amenity-text-content p {
        font-size: 18px;
    }
    .content.amenities .amenity-control {
        justify-content: center;
        width: 100%;
    }
}
@media (max-width: 1366.98px) {
    .content.amenities .amenity-item .amenity-text-content h4 {
        font-size: 42px;
    }
    .content.amenities .amenity-item .amenity-text-content.amenity-amani,
    .content.amenities .amenity-item .amenity-text-content.amenity-alora {
        min-width: 600px;
        max-width: 600px;
    }
    .content.amenities .amenity-item .amenity-text-content p {
        font-size: 16px;
    }
    
    .content.amenities .amenity-item .amenity-text-content.amenity-amani {
        margin-right: 0;
    }
    .content.amenities .amenity-item .amenity-text-content.amenity-alora {
        margin-left: 0;
    }
    .content.amenities .amenity-item .amenity-text-content {
        max-width: 540px;
    }
    .content.amenities .amenity-item .amenity-img-content {
        margin-right: 60px;
    }
}
@media (max-width: 1280.98px) {
    .content.amenities .amenity-item .amenity-text-content {
        max-width: 480px;
    }
    .content.amenities .amenity-item .amenity-text-content.amenity-amani,
    .content.amenities .amenity-item .amenity-text-content.amenity-alora {
        padding: 40px 24px;
        min-width: 500px;
        max-width: 500px;
    }
    .content.amenities .amenity-item .amenity-text-content h4 {
        font-size: 36px;
    }
}
@media (max-width: 1024.98px) {
    .content.amenities .amenity-item .amenity-text-content {
        max-width: 420px;
    }
    .content.amenities .amenity-item {
        flex-direction: column;
        justify-content: flex-start;
    }
    .content.amenities .amenity-item .amenity-text-content {
        margin: 0 auto;
        margin-top: 100px;
        max-width: 640px;
        padding: 0;
        margin-bottom: 24px;
    }
    .content.amenities .amenity-item .amenity-img-content img.amenity-img {
        max-width: 640px;
    }
    .content.amenities .amenity-item .amenity-img-content {
        margin: 0 auto;
    }
    .content.amenities .amenities-cont .amenity-item:last-child {
        flex-direction: row;
    }
    .content.amenities .amenity-item .amenity-text-content.amenity-amani,
    .content.amenities .amenity-item .amenity-text-content.amenity-alora {
        padding: 40px 24px;
        min-width: 480px;
        max-width: 480px;
    }
}
@media (max-width: 992px) {
    .content.amenities .amenity-item .amenity-text-content.amenity-amani,
    .content.amenities .amenity-item .amenity-text-content.amenity-alora {
        padding: 40px 16px;
        min-width: 400px;
        max-width: 400px;
    }
    .content.amenities .amenity-item .amenity-text-content.amenity-amani h4, .content.amenities .amenity-item .amenity-text-content.amenity-alora h4 {
        font-size: 32px;
    }
}
@media (max-width: 820px) {
    .content.amenities .amenity-item .amenity-text-content.amenity-amani,
    .content.amenities .amenity-item .amenity-text-content.amenity-alora {
        padding: 40px 24px;
        min-width: 38px;
        max-width: 380px;
    }
    .content.amenities .amenity-item .amenity-text-content.amenity-amani h4, .content.amenities .amenity-item .amenity-text-content.amenity-alora h4 {
        font-size: 32px;
    }
}

@media (max-width: 768.98px) and (max-height: 1024.98px) {
    .content.amenities .amenity-item .amenity-text-content {
        margin-top: 180px;
    }
    .content.amenities .amenity-item .amenity-text-content.amenity-amani,
    .content.amenities .amenity-item .amenity-text-content.amenity-alora {
        margin-top: 140px;
    }
}
@media (max-width: 767.98px) {
    .content.amenities .amenity-control {
        display: none;
    }
    .content.amenities .amenities-cont {
        display: none;
    }
    .content.amenities .amenity-control-mobile {
        z-index: 100;
        position: absolute;
        top: 42px;
        left: 50%;
        transform: translateX(-50%);
        /* display: flex; */
    }

    .content.amenities .amenity-item .amenity-text-content {
        margin: 0 auto;
        margin-top: 100px;
    }
    .content.amenities .amenity-item .amenity-img-content {
        margin: 0 auto;
        padding: 20px;
    }
    .content.amenities .amenity-item .amenity-text-content h4 span {
        font-size: 20px;
    }
    .content.amenities .amenity-item .amenity-text-content img.amenity-img {
        display: block;
    }
}

@media (min-width: 768px) {
    .content.amenities .amenities-cont-mobile {
        display: none;
    }
}
.content.amenities {
    overflow-y: auto;
}
.content.amenities .amenities-cont-mobile {
    position: relative;
    height: 100%;
}
.content.amenities .amenities-cont-mobile small.scroll-down {
    display: block;
    margin: auto;
    margin-bottom: 24px;
    color: #fff;
    font-weight: 200;
    letter-spacing: .1em;
    text-align: center;
}
.content.amenities .amenities-cont-mobile .amenity-item {
    display: flex;
    height: auto;
    min-height: 100%;
    padding: 48px 24px;
    box-sizing: border-box;
}
.content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content {
    margin: auto;
}
.content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content h4 {
    text-align: center;
}

.content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content p {
    font-weight: 400;
    text-align: justify;
    text-align-last: center; 
}

.content.amenities .amenities-cont-mobile .amenity-item:last-child {
    height: auto;
    min-height: unset;
}

.content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content img.amenity-img {
    margin-top: 24px;
}
.content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content span {
    display: block;
    text-align: center;
    font-size: 20px;
    margin-top: 8px;
}

.content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content {
    padding: 60px 24px;
}

.content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content img.amenity-img-hall {
    margin-top: 24px;
}
.content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content h4 {
    font-size: 32px;
}
@media (max-width: 576.98px) {
    .content.amenities .amenities-cont-mobile .amenity-item {
        min-height: unset;
        padding-top: 32px;
    }
}

@media (max-width: 375.98px) {
    .content.amenities .amenities-cont-mobile .amenity-item {
        padding: 48px 0;
        padding-top: 20px;
    }
    .content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content {
        padding: 60px 28px;
    }
    
    .content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content h4 {
        font-size: 28px;
    }
}

.content.amenities {
    background-color: transparent;
}
.content.amenities div.section1 {
}
.content.amenities div.section1::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    background: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/aerial-bg-v4.jpg') no-repeat center center;
    background-size: cover;
    will-change: transform;
    z-index: -1;
}

@media (max-width: 320.98px) {
    .content.amenities .amenities-cont-mobile .amenity-item {
        padding: 0;
    }
    .content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content {
        padding: 42px 24px;
    }
    .content.amenities .amenities-cont-mobile .amenity-item .amenity-text-content img.amenity-img {
        margin-top: 20px;
    }
}