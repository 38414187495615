.content.explore {
    scroll-behavior: smooth;
    background-color: transparent;

    padding-bottom: 0;
}

.content.explore main.main {
    position: relative;
    display: flex;
    min-height: 100%;
    width: 100%;

    background-size: cover;
    background-position: 100% 30%;
    background-repeat: no-repeat;
    max-width: 1920px;

    margin: auto;
}
.content.explore main.main h2 {
    z-index: 101;
    margin: 0 auto;
    
    font-size: 60px;
    font-family: 'Poppins';
    /* text-transform: uppercase; */
    word-spacing: .5rem;

    color: #e5a722;
}
.content.explore main.main h2 span {
    font-style: italic;
    font-weight: 600;
    margin-right: 6px;
}
.content.explore main.main .text-content {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    width: 40%;
    min-width: 600px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .9);

    padding: 80px;
    box-sizing: border-box;
}
.content.explore main.main .text-content p {
    font-size: 20px;
    text-align: justify;
    text-align-last: center;
}
.content.explore main.main .text-content p span {
    font-size: 28px;
    font-weight: bold;
    font-style: italic;
    color: #e5a722;
}
.content.explore main.main .text-content > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    width: 100%;
    box-sizing: border-box;
    margin-top: 12px;
}
.content.explore main.main .text-content a {
    display: inline-block;
    margin: 0 auto;
    margin-top: 16px;
    
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    
    background-color: #e5a722;
    color: #fff;
    padding: 8px;

    box-sizing: border-box;
    border: solid 2px #e5a722;
    border-radius: 40px;
    width: 100%;
    max-width: 300px;
}
.content.explore main.main .text-content a:first-child {
    /* margin-right: 12px; */
}
.content.explore main.main .text-content a:hover {
    /* border-bottom: solid 2px #e5a722; */
    background-color: transparent;
    color: #e5a722;
}

.content.explore div.buying-guide {
    position: relative;
    height: 100%;
    max-height: 900px;
    width: 100%;

    margin: auto;

    display: flex;
    flex-direction: column;
    /* background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/explore-lattice-bg.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; */
    padding: 24px 40px;
    box-sizing: border-box;
}
.content.explore div.buying-guide::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    background: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/explore-lattice-bg.jpg') no-repeat center center;
    background-size: cover;
    will-change: transform;
    z-index: -1;
}
.content.explore div.buying-guide h4 {
    margin: 0 auto;
    
    font-size: 32px;
    font-family: 'Poppins';
    
    color: #e5a722;
}

.content.explore div.buying-guide h4 em {
    font-size: 42px;
    font-weight: 600;
}

.content.explore div.buying-guide h4 em:first-child {
    display: inline-block;
    font-size: 60px;
    font-weight: 500;
    transform: translateY(8px);
}

.content.explore div.buying-guide .steps-cont {
    position: relative;
    width: 100%;
    display: flex;
    height: 100%;
    max-width: 1600px;
    max-height: 720px;
    margin: 0 auto;
}
.content.explore div.buying-guide .steps-cont div.body {
    display: flex;
    width: 90%;
    margin: auto;
    overflow: hidden;
    scroll-behavior: smooth;
}

.content.explore div.buying-guide .steps-cont .step-item {
    position: relative;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    align-items: center;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    
    padding: 0 12px;
    box-sizing: border-box;
}

.content.explore div.buying-guide .steps-cont .step-item img.step-title {
    align-self: flex-start;
    max-height: 72px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container .step-sub-title-cont,
.content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .step-sub-title-cont {
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 28px;

    width: 100%;

    /* padding: 0 24px; */
    box-sizing: border-box;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container .step-sub-title-cont h5.sub-title,
.content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .step-sub-title-cont h5.sub-title {
    display: inline-block;
    align-items: center;

    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 500;

    color: #fff;
    background-color: #404041;
    padding: 2px 20px;

    margin: 0;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container .step-sub-title-cont span.color-bar,
.content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .step-sub-title-cont span.color-bar {
    width: 100%;
    max-width: 420px;
    height: 4px;

    background-color: #e5a722;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container {
    display: flex;
    flex-direction: row;
    max-height: 100%;
    margin-top: 24px;
}

.content.explore div.buying-guide .steps-cont .step-item .guide-container .guide {
    margin-right: 60px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container .guide:last-child {
    margin-right: 0;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img {
    max-height: 400px; margin-left: 8px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-transfer {
    margin-right: 100px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-5percent {
    max-height: 120px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-docs-check {
    margin-left: 24px;
    max-height: 100px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-10percent {
    margin-left: 40px;
    max-height: 120px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-100percent {
    margin-left: 40px;
    max-height: 100px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-house-percent {
    margin-left: 24px;
    max-height: 80px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-house-money {
    margin-left: 24px;
    max-height: 100px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-house-hammer {
    max-height: 80px;
    margin-left: 24px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-house-check {
    max-height: 100px;
    margin-left: 24px;
}


.content.explore div.buying-guide .steps-cont .step-item .guide-container .guide-item {
    display: flex;
    align-items: center;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container .guide-item h5.item-title,
.content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .guide-item h5.item-title {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 500;

    color: #fff;
    background-color: #404041;
    padding: 4px 16px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container .guide-item h5.item-title span {
    font-size: 24px;
}
.content.explore div.buying-guide .steps-cont .step-item .guide-container .guide-item ul.item-list {
    max-width: 480px;
}

.content.explore div.buying-guide .steps-cont .step-item span.prev-btn {
    position: absolute;
    bottom: 32px;
    left: 40px;

    cursor: pointer;
}
.content.explore div.buying-guide .steps-cont .step-item span.next-btn {
    position: absolute;
    bottom: 32px;
    right: 40px;
    
    cursor: pointer;
}

.content.explore div.buying-guide .steps-cont .step-item > span {
    color: #e5a722;

    font-size: 20px;
    font-weight: 500;
    border-bottom: solid 2px transparent;
}
.content.explore div.buying-guide .steps-cont .scroll-button-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 20px;
}
.content.explore div.buying-guide .steps-cont .scroll-button-cont img {
    max-width: 64px;
}
.content.explore div.buying-guide .steps-cont .scroll-button-cont img:first-child {
    margin-left: -24px;
}

.content.explore div.buying-guide .steps-cont .scroll-button-cont img:active {
    transform: scale(.9);
}
/* .content.explore div.buying-guide .steps-cont .step-item > span:hover {
    border-bottom-color: #e5a722;
} */


.content.explore small.disclaimer {
    display: block;
    margin: 24px auto;
    max-width: 800px;

    text-align: center;
}


.content.explore div.news {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;

    box-sizing: border-box;
    padding-top: 60px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}


.content.explore div.news .news-header {
    margin-bottom: 24px;
    padding: 0 24px;
    box-sizing: border-box;
}
.content.explore div.news .news-header * {
    text-align: center;
}

.content.explore div.news .news-header p.desc {
    font-size: 18px;
    margin: 0;
    margin-top: 12px;
}
.content.explore div.news .news-header h2.title {
    font-size: 32px;
    font-family: 'Poppins';
    font-weight: 500;

    color: #e5a722;
    margin: 0;
}

.content.explore div.news .article {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 60px;
    padding: 24px;
    box-sizing: border-box;
}
.content.explore div.news .article h4 {
    font-size: 36px;
    text-align: center;
    /* text-transform: uppercase; */
    /* font-family: 'Poppins'; */
    margin: 0;
}
.content.explore div.news .article small {
    display: block;
    text-align: center;
}
.content.explore div.news .article h5 {
    font-size: 20px;
}
.content.explore div.news .article img {
    width: 80%;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 42px;
}
.content.explore div.news .article .article-content {
    display: flex;
    flex-direction: column;
}
.content.explore div.news .article:last-child .article-content {
    margin-top: 24px;
}
.content.explore div.news .article .article-content p {
    margin: 0;
    margin-bottom: 32px;
}
.content.explore div.news .article .article-content a {
    margin-left: auto;
    color: black;
    text-decoration: none;
    border-bottom: solid 1px black ;
}
.content.explore div.news .article .article-content a:hover {
    border-bottom-color: #4b80a9;
    color: #4b80a9;
}

.steps-cont-mobile {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    box-sizing: border-box;

    margin-top: 24px;
}
.steps-cont-mobile .step-item {
    margin-top: 24px;
    margin-bottom: 32px;
}

.steps-cont-mobile .step-item .step-title {
    max-height: 60px;
}

.steps-cont-mobile .step-item .guide-container .guide .guide-item {
    display: flex;
    align-items: center;
}
.steps-cont-mobile .step-item .guide-container .guide .guide-item img {
    max-height: 64px;
    margin-left: 16px;
}
@media (max-width: 1600px) {
    .content.explore div.buying-guide .steps-cont .scroll-button-cont img {
        max-width: 48px;
    }
}
@media (max-width: 1366.98px) and (min-width: 1280.98px) {
    .content.explore div.buying-guide .steps-cont .step-item img.step-title {
        max-height: 48px;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-transfer {
        display: none;
    }
    .content.explore div.buying-guide h4 {
        margin-top: 28px;
        font-size: 28px;
    }
    .content.explore div.buying-guide h4 em {
        font-size: 36px;
    }
    .content.explore div.buying-guide h4 em:first-child {
        font-size: 48px;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container .step-sub-title-cont, .content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .step-sub-title-cont {
        margin-top: 0px;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container .guide-item h5.item-title, .content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .guide-item h5.item-title {
        margin: 16px 0;
    }
}


@media (max-width: 1280px) {
    .content.explore div.buying-guide .steps-cont .step-item img.step-title {
        max-height: 60px;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container {
        margin-top: 12px;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container .guide {
        margin-right: 32px;
    }

    
    .content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-transfer {
        max-height: 200px;
        margin: auto 0;
        margin-top: 120px;
        margin-right: 24px;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-5percent {
        max-height: 80px;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-docs-check {
        max-height: 80px;
    }

    .content.explore div.buying-guide .steps-cont .step-item:nth-child(2) .guide-container,
    .content.explore div.buying-guide .steps-cont .step-item:nth-child(4) .guide-container {    
        flex-direction: column;
    }
    .content.explore div.buying-guide .steps-cont .step-item:nth-child(4) .guide-container .guide:last-child {
        display: flex;
        flex-wrap: wrap;
    }
    .content.explore div.buying-guide .steps-cont .step-item:nth-child(4) .guide-container .guide-item:last-child {
        margin-left: 12px;
    }
}

@media (max-width: 1279.98px) {
    .content.explore div.buying-guide .steps-cont {
        max-height: unset;
    }
    .content.explore div.buying-guide .steps-cont .scroll-button-cont {
        display: none;
    }
    .content.explore div.buying-guide {
        max-height: unset;
        height: auto;
    }
    .content.explore div.buying-guide .steps-cont div.body {
        flex-direction: column;
        max-height: unset;

        margin-top: 16px;
        overflow: unset;
    }
    .content.explore div.buying-guide .steps-cont .step-item {
        align-items: flex-start;
    }
    .content.explore div.buying-guide .steps-cont .step-item > span {
        display: none;
    }

    .content.explore div.buying-guide .steps-cont .step-item:first-child .guide-container {
        flex-direction: column;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-transfer {
        display: none;
        align-self: flex-start;
    }

    .content.explore div.buying-guide .steps-cont .step-item .guide-container img.icon-10percent {
        max-height: 80px;
    }
    .content.explore div.buying-guide .steps-cont .step-item:nth-child(4) .guide-container .guide-item:last-child {
        margin-left: 0;
    }
    .content.explore div.buying-guide small {
        margin-top: 32px;
    }
    
}
@media (max-width: 1024.98px) {
    .content.explore div.buying-guide .steps-cont .step-item .guide-container {
        flex-direction: column;
    }
    .content.explore main.main {
        background-position: 0% 100%;
    }
    .content.explore main.main .text-content {
        width: 100%;
        min-width: unset;
        background-color: rgba(255, 255, 255, .85);
    }
}
@media (min-width: 768px) {
    .content.explore div.buying-guide .steps-cont-mobile {
        display: none;
    }
    .content.explore div.buying-guide .steps-cont .step-item img.step-title {
        max-height: 54px;
    }
}
@media (max-width: 767.98px) {
    .content.explore div.buying-guide .steps-cont {
        display: none;
    }
    .content.explore div.news {
        background-attachment: unset;
        background-repeat: repeat-y;
        background-size: contain;
    }
}
@media (max-width: 576.98px) {
    .content.explore main.main .text-content {
        padding: 40px;
    }
    .content.explore main.main h2 {
        text-align: center;
        font-size: 42px;
    }

    .content.explore div.buying-guide h4 {
        text-align: center;
        line-height: 1.5em;
    }
    
    .steps-cont-mobile .step-item .guide-container .guide .guide-item img {
        display: none;
    }
    
    .steps-cont-mobile .step-item .step-title {
        max-height: 48px;
    }
    .content.explore div.buying-guide {
        padding: 0 28px;
        padding-top: 28px;
    }
    .content.explore div.buying-guide h4 em {
        font-size: 36px;
    }
    .content.explore div.buying-guide h4 em:first-child {
        font-size: 48px;
    }
    .content.explore div.buying-guide h4 {
        font-size: 28px;
    }
    .content.explore div.news .article .article-content p {
        text-align: justify;
    }
    .content.explore div.news .article h5 {
        text-align: center;
    }
    .content.explore div.news .article .article-content a {
        margin: 0 auto;
    }
}

@media (max-width: 375.98px) {
    .content.explore main.main h2 {
        /* display: flex;
        flex-direction: column; */
        font-size: 36px;
    }
    .content.explore main.main h2 span {
        /* margin: 0; */
    }
    .content.explore main.main .text-content {
        padding: 0;
    }
    .content.explore main.main .text-content p {
        padding: 0 32px;
        font-size: 18px;
    }
    .content.explore main.main .text-content a {
        font-size: 16px;
        padding: 4px;
        width: 90%;
    }

    .content.explore div.buying-guide {
        padding-top: 42px;
    }
    .content.explore div.buying-guide h4 {
        font-size: 24px;
    }
    .content.explore div.buying-guide h4 em:first-child {
        font-size: 42px;
    }
    .content.explore div.buying-guide h4 em {
        font-size: 32px;
    }
    .steps-cont-mobile .step-item .step-title {
        max-height: 42px;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container .guide-item h5.item-title,
    .content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .guide-item h5.item-title {
        font-size: 18px;
        margin: 24px 0;
    }
    .content.explore div.buying-guide .steps-cont .step-item .guide-container .step-sub-title-cont h5.sub-title, .content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .step-sub-title-cont h5.sub-title {
        font-size: 18px;
    }
    .content.explore small.disclaimer {
        padding: 0 32px;
    }
    .content.explore div.news .article {
        padding: 24px 32px;
    }
    .content.explore div.news .article img {
        width: 100%;
    }
    .content.explore div.news .article h4 {
        line-height: 1em;
        margin-bottom: 8px;
    }
    .content.explore div.news .article h5 {
        margin-top: 0;
        text-align: right;
    }
}
@media (max-width: 374.98px) {
    .content.explore div.buying-guide {
        padding: 0 20px;
    }
    .steps-cont-mobile .step-item .guide-container .guide .guide-item {
        flex-direction: column;
    }
    .content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .guide-item h5.item-title {
        margin: 0;
        margin-top: 24px;
    }
    .steps-cont-mobile .step-item .step-title {
        max-height: 40px;
    }
    .content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .step-sub-title-cont h5.sub-title {
        font-size: 16px;
    }
    .content.explore div.buying-guide .steps-cont-mobile .step-item .guide-container .guide-item ul.item-list {
        list-style-position: inside;
        padding-inline-start: 24px;
        padding-inline-end: 24px;
    }
}

@media (max-width: 320.98px) {
    .content.explore main.main .text-content {
        justify-content: flex-start;
    }
    .content.explore main.main .text-content p {
        font-size: 16px;
        line-height: 1.6em;
        text-align: center;
        margin: 0;
        margin-bottom: 8px;
    }
    .content.explore main.main .text-content a:first-child {
        margin-top: 0;
    }
    .content.explore main.main .text-content a {
        margin-top: 8px;
    }
    .content.explore main.main h2 {
        margin-top: 42px;
        margin-bottom: 8px;
        font-size: 32px;
    }
    .content.explore main.main .text-content p span {
        font-size: 24px;
    }

    
    .content.explore div.buying-guide {
        padding-top: 42px;
    }

    .content.explore div.news {
        padding-top: 32px;
    }
    .content.explore div.news .news-header p.desc {
        font-size: 16px;
    }
    .content.explore div.news .article {
        padding: 24px 28px;
    }
}