.content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.content div.section1 {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    width: 100%;
    max-width: 1600px;

    margin: auto;

    padding: 120px 60px;
    padding-top: 90px;
    box-sizing: border-box;
}

.content div.section1 h2 {
    font-size: 42px;
    font-family: "LT Bulletin";

    text-align: center;
    margin: 0;
    margin-bottom: 12px;
}

.content div.section1 .contact-banner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1100px;
    min-height: 540px;

    margin: auto;
    margin-top: 24px;

    background-color: #fff;

    overflow: hidden;
    border-radius: 8px;

    box-shadow: 0px 4px 12px rgba(0, 0, 0, .2);
}
.content div.section1 .contact-banner .banner-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    
    padding: 24px;
    box-sizing: border-box;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.content div.section1 .contact-banner .banner-card .card-logo {
    z-index: 24;
    position: relative;
    max-width: 42px;
}
.content div.section1 .contact-banner .banner-card .card-overlay {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 117, 148, .92);
}
.content div.section1 .contact-banner .banner-card h4.card-text {
    z-index: 24;
    position: relative;

    font-size: 36px;
    font-family: "Poppins";
    font-weight: 400;

    color: #fff;

    margin: 0;
    margin-top: 72px;

    line-height: 1.5em;
}
.content div.section1 .contact-banner .banner-card img.assurance-text {
    display: inline-block;
    z-index: 24;
    position: relative;
    height: 72px;
    align-self: flex-start;

    margin-top: 12px;
}

.content div.section1 .contact-banner .banner-card h4.card-text span {
    display: inline-block;
    font-size: 60px;
    font-style: italic;
    font-weight: 600;
    /* font-family: "Manta Style"; */
    color: rgba(229, 167, 36, 1);

    margin-top: 8px;
    opacity: 0;
    transform: translateX(-12px);
    transition: all 600ms ease-in-out;
}

.content div.section1 .contact-banner .banner-card h4.card-text span.show {
    opacity: 1;
    transform: translateX(0);
}


.content div.section1 .contact-banner .banner-card .contact-info {
    z-index: 24;
    position: relative;

    margin-top: auto;
}
.content div.section1 .contact-banner .banner-card .contact-info > div {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.content div.section1 .contact-banner .banner-card .contact-info > div p {
    color: #fff; 
    margin: 0;
    margin-left: 10px;

    font-size: 14px;
}

.content div.section1 .contact-banner .form-cont {
    position: relative;
    width: auto;

}


.content div.section1 .contact-banner .form-cont form {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: inherit;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
    
    padding: 28px 20px;
    box-sizing: border-box;
}

.content div.section1 .contact-banner .form-cont form .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 0 12px;
    margin-bottom: 24px;

    min-width: 280px;
    width: 100%;
    max-width: 300px;

    box-sizing: border-box;
}

.content div.section1 .contact-banner .form-cont form .input-group.subject,
.content div.section1 .contact-banner .form-cont form .input-group.message {
    max-width: 100%;
}


.content div.section1 .contact-banner .form-cont form .input-group input {
    
    box-sizing: border-box;
    border-radius: 2px;
    border: solid 1px black;
    background-color: transparent;

}


.content div.section1 .contact-banner .form-cont form .input-group input[type="text"],
.content div.section1 .contact-banner .form-cont form .input-group input[type="email"] {
    padding: 6px 8px;
    font-size: 16px;
    margin-top: 8px;
    width: 100%;
    
    outline-color: #4b80a9;
}

.content div.section1 .contact-banner .form-cont form .input-group > label {
    font-weight: 600;
    /* font-style: italic; */
    font-size: 14px;
}

.content div.section1 .contact-banner .form-cont form .input-group .radio-btn {
    display: flex;
    flex-flow: row wrap;
}

.content div.section1 .contact-banner .form-cont form .input-group .radio-btn div {
    display: flex;
    flex-direction: row;
    align-items: center;

    min-width: 140px;
    
    margin: 12px 0;
}

.content div.section1 .contact-banner .form-cont form .input-group .radio-btn div input {
    margin-right: 8px;
    outline-color: #4b80a9;
}


.content div.section1 .contact-banner .form-cont form .input-group textarea {
    width: 100%;
    max-width: 420px;
    margin-top: 8px;
    border: solid 1px black;

    outline-color: #4b80a9;
    padding: 8px;
    box-sizing: border-box;
}

.content div.section1 .contact-banner .form-cont form .submit {
    display: flex;
    width: 100%;
    margin: 12px;
}
.content div.section1 .contact-banner .form-cont form .submit button {
    min-width: 160px;

    padding: 8px;
    font-size: 16px;

    border: solid 1px black;
    border-radius: 4px;
    background-color: transparent;

    
    outline-color: #4b80a9;
    cursor: pointer;
}

.content div.section1 .contact-banner .form-cont form .submit button:hover {
    color: #fff;
    background-color: #4b80a9;
    border-color: #4b80a9;
}



@media (max-width: 992px) {
    .content div.section1 {
        padding: 60px 32px;
    }
}
@media (max-width: 768px) {
    .content div.section1 {
        padding: 60px 20px;
    }

    .content div.section1 .contact-banner {
        flex-direction: column;
        background-color: transparent;
        box-shadow: none;

        overflow: auto;
        border-radius: 0;
    }

    .content div.section1 .contact-banner .banner-card {
        min-height: 480px;

        border-radius: 8px;
        overflow: hidden;
        margin: 0 auto;
    }
    .content div.section1 .contact-banner .banner-card h4.card-text {
        margin-top: 32px;
    }


    .content div.section1 .contact-banner .form-cont {
        margin-top: 42px;
        background-color: #fff;
    }

    .content div.section1 .contact-banner .form-cont form {
        justify-content: center;
    }
    .content div.section1 .contact-banner .form-cont form .input-group {
        align-items: center;
        max-width: 420px;
    }
    .content div.section1 .contact-banner .form-cont form .submit {
        justify-content: center;
    }
    

    .content div.section1 .contact-banner .form-cont form .input-group {
        margin: 0;
        margin-bottom: 20px;
    }

    .content div.section1 .contact-banner .form-cont form .input-group input[type="text"], .content div.section1 .contact-banner .form-cont form .input-group input[type="email"] {
        text-align: center;
    }
}


@media (max-width: 576px) {
    .content div.section1 {
        padding: 32px 20px;
        padding-bottom: 60px;
    }
}
@media (max-width: 375.98px) {
    .content div.section1 .contact-banner .banner-card {
        max-width: 280px;
        min-height: 320px;
    }
    .content div.section1 .contact-banner .banner-card h4.card-text {
        font-size: 28px;
        margin: 24px 0;
    }
    .content div.section1 .contact-banner .banner-card h4.card-text span {
        font-size: 48px;
    }
}
@media (max-width: 375px) {
    .content div.section1 {
        padding: 32px 12px;
    }
    .content div.section1 .contact-banner .banner-card {
        min-height: 420px;
    }
    .content div.section1 .contact-banner .banner-card h4.card-text {
        font-size: 24px;
    }
    .content div.section1 .contact-banner .banner-card img.assurance-text {
        height: 48px;
    }

    .content div.section1 .contact-banner .form-cont form .input-group {
        min-width: unset;
    }
    .content div.section1 .contact-banner .form-cont form .input-group .radio-btn {
    }

}
@media (max-width: 320.98px) {
    .content div.section1 {
        padding: 24px 0;
        padding-bottom: 0;
    }
    .content div.section1 h2 {
        margin-bottom: 0;
    }

    .content div.section1 .contact-banner .banner-card {
        min-height: 320px;
        max-width: 240px;
        padding: 16px;
    }
    .content div.section1 .contact-banner .banner-card .card-logo {
        max-width: 32px;
    }
    .content div.section1 .contact-banner .banner-card h4.card-text {
        font-size: 20px;
        margin: 16px 0;
    }
    .content div.section1 .contact-banner .banner-card h4.card-text span {
        font-size: 36px;
    }
    .content.contact {
        padding-bottom: 0;
    }
    .content div.section1 .contact-banner .form-cont form {
        padding: 42px 28px;
    }
}