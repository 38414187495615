.content.about-us .section1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 1600px;
    min-height: 100%;

    margin: auto;

    box-sizing: border-box;
    
    padding: 100px 40px;
    padding-bottom: 0;
    
}

.content.about-us .section1 h4 {
    font-size: 42px;
    font-family: "LT Bulletin";
    margin: 0;
    /* color: #4b80a9;  */
    /* color: rgba(229, 167, 36, 1); */
}


.content.about-us .section1 p {
    width: 100%;
    max-width: 1000px;
    text-align: center;
}

.content.about-us .section1 h5 {
    font-size: 20px;
    font-family: 'Poppins';
    margin: 0;
    margin-top: 32px;
}



.content.about-us .section1 .cards-cont {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
}
.content.about-us .section1 .cards-cont a {
    text-decoration: none;
}
.content.about-us .section1 .cards-cont .card-item {
    position: relative;
    display: flex;

    width: 100%;
    max-width: 280px;

    min-height: 380px;
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    padding: 20px;
    box-sizing: border-box;
    
    border-radius: 8px;
    overflow: hidden;

    margin: 20px;
    cursor: pointer;

    transition: transform 400ms ease-in-out;
}

.content.about-us .section1 .cards-cont .card-item:hover {
    transform: scale(1.05);
}

.content.about-us .section1 .cards-cont .card-item div.anim-cont {
    z-index: 30;
    position: relative;

    margin: auto 0;
    margin-top: 72px;
}
.content.about-us .section1 .cards-cont .card-item div.anim-cont .anim-text {
    display: inline-block;
    height: 54px;
    margin-top: 8px;
    /* margin: 12px 0; */
    /* margin-left: -8px; */
}

.content.about-us .section1 .cards-cont .card-item .card-logo {
    z-index: 30;
    position: absolute;
    top: 20px;
    left: 20px;
    max-width: 42px;
}
.content.about-us .section1 .cards-cont .card-item .card-overlay {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    /* background-color: rgba(75, 128, 169, .85); */
    /* background-color: rgba(46, 79, 105, .85); */
    background-color: rgba(50, 117, 148, .9);
}

.content.about-us .section1 .cards-cont .card-item p {
    z-index: 21;
    position: relative;

    margin: auto;
    /* margin-top: 40px; */

    font-size: 28px;
    font-family: "Poppins";

    text-align: left;
    line-height: 1.5em;

    color: #fff;
}
.content.about-us .section1 .cards-cont .card-item p span {
    display: inline-block;

    font-family: "Manta Style";
    font-weight: bold;
    font-size: 60px;

    line-height: 1em;
    margin-top: 24px;
    
    color: rgba(229, 167, 36, 1);
}


.content.about-us .section1 p.subDesc {
    margin-top: 20px;
    max-width: 600px;
}


.content.about-us .section2 {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1600px;
    /* min-height: 100%; */

    margin: auto;
    padding-bottom: 160px;
}
.content.about-us .section2 .text-content {
    margin: 0 auto;
    /* margin-top: 120px; */
    width: 100%;
    max-width: 1000px;

    padding: 0 42px;
    box-sizing: border-box;
}
.content.about-us .section2 .text-content .section-img {
    height: 360px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 24px;
}

.content.about-us .section2 .text-content h4 {
    font-size: 42px;
    text-align: center;
    margin: 0;
    margin-bottom: 42px;
}

.content.about-us .section2 .text-content h5.bay-title {
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 300;
    text-align: center;

    margin: 0;
    margin-bottom: 24px;
}
.content.about-us .section2 .text-content h4.bay-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    font-weight: 400;
    line-height: 1em;    
    color: #414143;
    
    margin-bottom: 4px;
    /* letter-spacing: -.025em; */
}
.content.about-us .section2 .text-content h4.bay-title span {
    font-family: 'LT Bulletin';
    font-size: 54px;

    color: #e5a722;
    margin-left: 8px;
}

.content.about-us .section2 .text-content p {
    text-align: justify;
    text-align-last: center;
}


@media (max-width: 1024px) {
    .content.about-us .section1 {
        padding: 60px 32px;
    }
    .content.about-us .section2 .text-content {
        margin-top: 60px;
    }
}
@media (max-width: 576.98px) {
    .content.about-us .section1 h4 {
        font-size: 32px;
    }
    .content.about-us .section2 .text-content h4 {
        font-size: 32px;
    }
    .content.about-us .section1 > p {
        text-align: justify;
        text-align-last: center;
        box-sizing: border-box;
    }
    .content.about-us .section2 .text-content h4.bay-title {
        display: block;
        font-size: 36px;
        margin-bottom: 8px;
    }
    .content.about-us .section2 .text-content h4.bay-title span {
        font-size: 42px;
        margin-left: 0;
    }
    .content.about-us .section2 .text-content h5.bay-title {
        font-size: 18px;
    }
    .content.about-us .section2 .text-content .section-img {
        height: 240px;
    }
    .content.about-us .section1 .cards-cont .card-item {
        margin: auto;
        margin-bottom: 42px;
    }
    .content.about-us .section1 .cards-cont a:first-child .card-item {
        margin-top: 24px;
    }
    .content.about-us .section1 .cards-cont a:last-child .card-item {
        margin-bottom: 0;
    }
    .content.about-us .section2 .text-content {
        margin-top: 32px;
    }
}
@media (max-width: 375.98px) {
    .content.about-us .section1 {
        padding: 60px 12px;
        padding-top: 48px;
    }
    .content.about-us .section1 h4 {
        font-size: 36px;
    }
    .content.about-us .section1 > p {
        padding: 0 24px;
    }
    .content.about-us .section2 .text-content{
        padding: 0 32px;
        /* margin-bottom: 100px; */
    }
    .content.about-us .section2 .text-content .section-img {
        height: 180px;
    }

    .content.about-us .section1 .cards-cont .card-item {
        max-width: 240px;
        min-height: 320px;
    }
    .content.about-us .section1 .cards-cont .card-item p {
        font-size: 24px;
    }
    .content.about-us .section1 .cards-cont .card-item div.anim-cont .anim-text {
        height: 48px;
    }
    .content.about-us .section2 .text-content h5.bay-title {
        font-size: 16px;
    }
    .content.about-us .section2 {
        padding-bottom: 60px;
    }
}
@media (max-width: 320.98px) {
    .content.about-us .section1 > p {
        padding: 0 12px;
    }
    .content.about-us .section2 .text-content {
        padding: 0 24px;
    }
}