.content.tent {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.content.tent main.main {
    display: flex;
    align-items: flex-start;
    position: relative;
    height: 100%;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/marketing-tent/marketing-tent-main-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.content.tent main.main img.sights-logo {
    max-width: 240px;
    margin-top: 5%;
    margin-left: 5%;
}


.content.tent main.main div.text-content {
    margin-left: auto;
    margin-right: 5%;
    margin-top: 5%;   
}

.content.tent main.main div.text-content a {
    position: relative;
    color: #2f2f2f;
    text-decoration: none;
}
.content.tent main.main div.text-content a:hover p {
    transform: translateX(-16px);
    letter-spacing: .05rem;
}

.content.tent main.main div.text-content p {
    font-size: 20px;
    font-weight: 500;
    text-align: right;
    margin: 0;
    margin-bottom: 12px;

    opacity: 0;
    transform: translate(-20px);

    transition: all 600ms ease-in-out;
}

.content.tent main.main div.text-content p.show {
    opacity: 1;
    transform: translateX(0);
}

.content.tent main.main div.text-content p > span {
    font-size: 32px;
    font-weight: 600;
    font-style: italic;
    
    color: #E5A724;
    margin: 0 4px;
}


.content.tent .section2.carousel {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    max-height: 800px;

    margin: auto;

    overflow: hidden;

    scroll-behavior: smooth;
}

.content.tent .section2.carousel .carousel-item {
    position: relative;
    min-width: 100%;
    height: inherit;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.content.tent .section2.carousel .carousel-item .color-overlay {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.1);
}
.content.tent .section2.carousel .carousel-item .text-content {
    z-index: 60;
    position: absolute;
    display: flex;
    flex-direction: column;

    background-color: #FFF4DB;

    border-radius: 4px;

    /* width: 100%; */
    max-width: 600px;
    min-height: 120px;
    padding: 28px 42px;
    box-sizing: border-box;
    
    transform: translateX(24px);
    opacity: 0;

    transition: all 500ms ease-in-out 800ms;
}

.content.tent .section2.carousel .carousel-item:nth-child(1) .text-content {
    right: 10%;
}
.content.tent .section2.carousel .carousel-item:nth-child(2) .text-content {
    right: 6%;
    max-width: 720px;
}
.content.tent .section2.carousel .carousel-item:nth-child(3) .text-content {
    left: 10%;
    max-width: 720px;
    transform: translateX(-24px);
}

.content.tent .section2.carousel .carousel-item.show .text-content {
    transform: translateX(0);
    opacity: 1;
}

.content.tent .section2.carousel .carousel-item .text-content p {
    margin: 0 auto;
    margin-bottom: 42px;

    font-size: 24px;
    font-weight: 500;
    text-align: justify;
    line-height: 1.5em;

    color: #2f2f2f;
}
.content.tent .section2.carousel .carousel-item:nth-child(2) .text-content p:nth-child(1) {
    margin-bottom: 12px;
}

.content.tent .section2.carousel .carousel-item .text-content a {
    position: absolute;
    bottom: 20px;
    right: 40px;

    color: #E5A724;
    font-size: 20px;

    text-decoration: none;
    transition: all 400ms ease-in-out;
}

.content.tent .section2.carousel .carousel-item .text-content a.back-btn {
    right: unset;
    left: 40px;
}
.content.tent .section2.carousel .carousel-item .text-content a:hover {
    transform: translateX(6px);
}

.content.tent .section2.carousel .carousel-item .text-content a.back-btn:hover {
    transform: translateX(-6px);
}

/* .content.tent .section2.carousel .carousel-item img {
    min-height: 100%;
    width: auto;
} */


.content.tent .section3.front-desk {
    position: relative;
    display: flex;

    height: 100%;
    width: 100%;
    max-width: 1920px; 
    margin: auto;
}


.content.tent .section3.front-desk .front-desk-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 6%;

    background-color: #fff;
    border-radius: 8px;

    box-sizing: border-box;
    padding: 0 40px;
    padding-bottom: 40px;

    width: 100%;
    max-width: 480px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, .25);
}

.content.tent .section3.front-desk .front-desk-content h4 {
    font-size: 42px;
    margin: 24px auto;   
    
}

.content.tent .section3.front-desk .front-desk-content div.front-desk-btns {
    display: flex;
    flex-direction: column;
}

.content.tent .section3.front-desk .front-desk-content div.front-desk-btns a {
    display: inline-block;
    padding: 12px;

    margin-bottom: 16px;
    cursor: pointer;

    border: solid 3px #48A6D3;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, .15);

    background-color: #48A6D3;
    border-radius: 42px;

    font-size: 16px;
    font-weight: 500;

    text-align: center;
    text-decoration: none;

    color: #2f2f2f;
    color: #fff;
}

.content.tent .section3.front-desk .front-desk-content div.front-desk-btns a:last-child {
    margin-bottom: 0;
}

.content.tent .section3.front-desk .front-desk-content div.front-desk-btns a:hover {
    background-color: #fff;
    color: #48A6D3;
}
.content.tent .section3.front-desk .front-desk-content div.front-desk-btns a:active {
    transform: scale(.98);
    box-shadow: unset;
}


@media (max-width: 1366.98px) {
    .content.tent .section2.carousel .carousel-item .text-content {
        padding: 28px;
    }
    .content.tent .section2.carousel .carousel-item .text-content p {
        font-size: 18px;
    }
    .content.tent .section2.carousel .carousel-item .text-content a.back-btn {
        left: 28px;
    }
    .content.tent .section2.carousel .carousel-item .text-content a {
        right: 28px;
    }
}
@media (max-width: 1024.98px) {
    .content.tent main.main img.sights-logo {
        max-width: 200px;
    }
    .content.tent main.main div.text-content p {
        font-size: 16px;
        margin-bottom: 6px;
    }
    .content.tent main.main div.text-content p > span {
        font-size: 28px;
    }
    .content.tent .section2.carousel .carousel-item .text-content p {
        font-size: 20px;
    }
}
@media (max-width: 768.98px) {
    .content.tent main.main img.sights-logo {
        max-width: 120px;
        margin: 0 auto;
        margin-top: 40px;
    }
    .content.tent main.main {
        background-position: center top;
    }
    .content.tent main.main div.text-content {
        margin-right: 0;
        margin-top: 0;
        position: absolute;
        left: 0;
        top: 160px;

        width: 100%;
    }
    .content.tent main.main div.text-content p {
        text-align: center;
    }

    .content.tent .section2.carousel .carousel-item .text-content {
        max-width: 460px;
    }
    
    .content.tent .section3.front-desk .front-desk-content {
        margin: auto;
    }
    .content.tent .section2.carousel .carousel-item:nth-child(2) .text-content {
        right: 20px;
    }
    .content.tent .section2.carousel .carousel-item:nth-child(3) .text-content {
        left: 20px;
    }
}
@media (max-width: 767.98px) {
    .content.tent .section2.carousel .carousel-item .text-content {
        position: static;
        margin: auto;

        padding: 24px 16px;
    }
    .content.tent .section2.carousel .carousel-item:nth-child(2) .text-content,
    .content.tent .section2.carousel .carousel-item:nth-child(3) .text-content {
        max-width: 540px;
    }
}

@media (max-width: 576.98px) {
    
    .content.tent main.main {
        height: calc(100vh - 72px);
        background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/marketing-tent/marketing-tent-mobile-bg.jpg');   
    }

    .content.tent main.main div.text-content {
        box-sizing: border-box;
        padding: 0 24px;
    }
    .content.tent main.main div.text-content p {
        font-size: 14px;
    }
    .content.tent main.main div.text-content p span {
        font-size: 24px;
    }

    .content.tent .section2.carousel .carousel-item {
        box-sizing: border-box;
        padding: 0 20px;
    }
    .content.tent .section2.carousel .carousel-item .text-content p {
        text-align: center;
        font-size: 18px;
    }
    .content.tent .section2.carousel .carousel-item .text-content a {
        right: 24px;
        bottom: 12px;
    }
    .content.tent .section2.carousel .carousel-item .text-content a.back-btn {
        left: 24px;
    }

    .content.tent .section3.front-desk {
        box-sizing: border-box;
        padding: 0 24px;
    }
    .content.tent .section3.front-desk .front-desk-content h4 {
        font-size: 32px;
    }
    .content.tent .section3.front-desk .front-desk-content div.front-desk-btns a {
        font-size: 14px;
        padding: 8px;
    }
    
    .content.tent main.main {
        background-position: center top;
    }
}

@media (max-width: 375.98px) {
    .content.tent main.main img.sights-logo {
        max-width: 100px;
    }
    .content.tent main.main div.text-content {
        top: 140px;
    }
}

@media (max-width: 320.98px) {
    .content.tent main.main img.sights-logo {
        margin-top: 20px;
        max-width: 60px;
    }
    .content.tent main.main div.text-content {
        top: 84px;
    }
    .content.tent main.main div.text-content p {
        font-size: 12px;
        line-height: 1.7em;
    }
    .content.tent .section2.carousel .carousel-item .text-content p {
        font-size: 15px;
    }
    .content.tent .section2.carousel .carousel-item .text-content a {
        font-size: 18px;
    }
    .content.tent .section3.front-desk {
    }
    .content.tent .section3.front-desk .front-desk-content {
        padding: 0 24px;
        padding-bottom: 24px;
    }
    .content.tent {
        padding-bottom: 0;
    }
    .content.tent .section3.front-desk .front-desk-content div.front-desk-btns a {
        padding: 4px 8px;
        margin-bottom: 12px;
    }
}